import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Datatable from "../../common/Datatable";
import {
  setSearchCriteria,
  setColumnVisibility,
  receiveProfilesSnapshot,
  receiveProfilesTotalCountSnapshot
} from "../../../actions/profileActions";
import { firestore } from "../../../firebase";

const styles = theme => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing.unit * 2
  },
  actionControl: {
    marginLeft: theme.spacing.unit
  },
  content: {
    display: "flex",
    flexDirection: "row"
  },
  tableContainer: {
    width: "100%"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  menu: {
    width: 200
  }
});

class Profiles extends Component {
  state = {
    profiles: [],
    collection: "listings"
  };

  componentDidMount() {
    this.unsubscribeFeaturedProfilesListener = this.registerFeaturedProfilesSnapshotListener(
      this.props.profiles
    );
    this.unsubscribeFeaturedProfilesTotalCountListener = this.registerFeaturedProfilesTotalCountSnapshotListener();
  }

  componentWillUnmount() {
    this.unsubscribeFeaturedProfilesListener();
    this.unsubscribeFeaturedProfilesTotalCountListener();
  }

  getFeaturedProfiles = collection =>
    this.getFeaturedProfilesQuery(collection).get();

  getFeaturedProfilesQuery = collection =>
    firestore.collection(collection).where("featured", "==", true);

  getFeaturedProfilesQueryWithSearchCriteria = (
    collection,
    order,
    orderBy,
    rowsPerPage,
    page,
    reset
  ) => {
    const {
      profiles,
      profiles: { endBefore, startAfter }
    } = this.props;

    let query = this.getFeaturedProfilesQuery(collection);

    if (!reset) {
      if (page > profiles.page) {
        query = query.orderBy(orderBy, order).startAfter(startAfter);
      } else if (page < profiles.page) {
        query = query
          .orderBy(orderBy, order === "asc" ? "desc" : "asc")
          .startAfter(endBefore);
      } else {
        query = query.orderBy(orderBy, order);
      }
    } else {
      query = query.orderBy(orderBy, order);
    }

    query = query.limit(rowsPerPage);

    return query;
  };

  maybeReverseSnapshots = (snapshot, previousPage, currentPage, reset) => {
    let snapshots = snapshot.docs;
    if (!reset) {
      if (previousPage < currentPage) {
        snapshots = snapshots.reverse();
      }
    }
    return snapshots;
  };

  registerFeaturedProfilesSnapshotListener = (
    { order, orderBy, rowsPerPage, page },
    reset
  ) => {
    const { receiveProfilesSnapshot } = this.props;
    const {
      profiles: { page: currentPage }
    } = this.props;
    const { collection } = this.state;
    return this.getFeaturedProfilesQueryWithSearchCriteria(
      collection,
      order,
      orderBy,
      rowsPerPage,
      page,
      reset
    ).onSnapshot(snapshot => {
      const snapshots = this.maybeReverseSnapshots(
        snapshot,
        page,
        currentPage,
        reset
      );
      const profiles = snapshots.map(doc => ({ ...doc.data(), id: doc.id }));
      const endBefore = snapshots[0];
      const startAfter = snapshots[profiles.length - 1];
      receiveProfilesSnapshot(profiles, endBefore, startAfter);
    });
  };

  registerFeaturedProfilesTotalCountSnapshotListener = () => {
    const { collection } = this.state;
    const { receiveProfilesTotalCountSnapshot } = this.props;
    return this.getFeaturedProfilesQuery(collection).onSnapshot(snapshot => {
      let totalCount = snapshot.size;
      receiveProfilesTotalCountSnapshot(totalCount);
    });
  };

  resetData = () => {
    this.unsubscribeFeaturedProfilesListener();
    this.unsubscribeFeaturedProfilesTotalCountListener();
    this.unsubscribeFeaturedProfilesListener = this.registerFeaturedProfilesSnapshotListener(
      this.props.profiles,
      true
    );
    this.unsubscribeFeaturedProfilesTotalCountListener = this.registerFeaturedProfilesTotalCountSnapshotListener();
  };

  handleRetrieveData = (searchCriteria, reset) => {
    const { setSearchCriteria } = this.props;
    this.unsubscribeFeaturedProfilesListener();
    this.unsubscribeFeaturedProfilesListener = this.registerFeaturedProfilesSnapshotListener(
      searchCriteria,
      reset
    );
    setSearchCriteria(searchCriteria);
  };

  handleFilterMenuItemClick = column => {
    this.props.setColumnVisibility(column);
  };

  handleRowClick = row => {
    const { collection } = this.state;
    this.props.history.push(`/editor/${collection}/${row.id}`);
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value }, () => {
      this.props.setSearchCriteria({ page: 0 });
      this.resetData();
    });
  };

  render() {
    const { collection } = this.state;
    const { profiles, classes } = this.props;
    return (
      <div>
        <div className={classes.actions}>
          <TextField
            select
            label="Profile Type"
            className={classes.textField}
            value={collection}
            onChange={this.handleChange("collection")}
            SelectProps={{
              MenuProps: {
                className: classes.menu
              }
            }}
            margin="normal"
          >
            <MenuItem value={"listings"}>Listings</MenuItem>
            <MenuItem value={"brands"}>Brands</MenuItem>
          </TextField>
        </div>
        <div className={classes.content}>
          <div className={classes.tableContainer}>
            <Datatable
              title={`Featured Profiles`}
              data={profiles}
              onFilterMenuItemClick={this.handleFilterMenuItemClick}
              onRetrieveData={this.handleRetrieveData}
              onRowClick={this.handleRowClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

Profiles.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ profiles }) => {
  return { profiles };
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      {
        setSearchCriteria,
        setColumnVisibility,
        receiveProfilesSnapshot,
        receiveProfilesTotalCountSnapshot
      }
    )(Profiles)
  )
);
