import * as ActionTypes from "./actionTypes";

export const setSearchCriteria = searchCriteria => {
  return {
    type: ActionTypes.SET_ADS_SEARCH_CRITERIA,
    searchCriteria
  };
};

export const setColumnVisibility = column => {
  return {
    type: ActionTypes.SET_ADS_COLUMN_VISIBILITY,
    column
  };
};

export const receiveAdsSnapshot = (ads, endBefore, startAfter) => {
  return {
    type: ActionTypes.RECEIVE_ADS_SNAPSHOT,
    ads,
    endBefore,
    startAfter
  };
};

export const receiveAdsTotalCountSnapshot = totalCount => {
  return {
    type: ActionTypes.RECEIVE_ADS_TOTAL_COUNT_SNAPSHOT,
    totalCount
  };
};
