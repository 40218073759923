import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Datatable from "../../common/Datatable";
import {
  setSearchCriteria,
  receiveClientsSnapshot,
  receiveClientsTotalCountSnapshot,
  setColumnVisibility
} from "../../../actions/clientActions";
import { firestore } from "../../../firebase";

const styles = theme => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing.unit * 2
  },
  actionControl: {
    marginLeft: theme.spacing.unit
  },
  content: {
    display: "flex",
    flexDirection: "row"
  },
  tableContainer: {
    width: "100%"
  },
  tableContainerZoom: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: "calc(100% - 300px) !important"
  }
});

class Clients extends Component {
  state = {};

  componentDidMount() {
    this.unsubscribeClientsListener = this.registerClientsSnapshotListener(
      this.props.clients
    );
    this.unsubscribeClientsTotalCountListener = this.registerClientsTotalCountSnapshotListener();
  }

  componentWillUnmount() {
    this.unsubscribeClientsListener();
    this.unsubscribeClientsTotalCountListener();
  }

  getClients = () => {
    let query = firestore.collection("clients");
    return query;
  };

  getClientsWithSearchCriteria = (order, orderBy, rowsPerPage, page, reset) => {
    const {
      clients,
      clients: { endBefore, startAfter }
    } = this.props;

    let query = this.getClients();

    if (!reset) {
      if (page > clients.page) {
        query = query.orderBy(orderBy, order).startAfter(startAfter);
      } else if (page < clients.page) {
        query = query
          .orderBy(orderBy, order === "asc" ? "desc" : "asc")
          .startAfter(endBefore);
      } else {
        query = query.orderBy(orderBy, order);
      }
    } else {
      query = query.orderBy(orderBy, order);
    }

    query = query.limit(rowsPerPage);

    return query;
  };

  maybeReverseSnapshots = (snapshot, previousPage, currentPage, reset) => {
    let snapshots = snapshot.docs;
    if (!reset) {
      if (previousPage < currentPage) {
        snapshots = snapshots.reverse();
      }
    }
    return snapshots;
  };

  registerClientsSnapshotListener = (
    { order, orderBy, rowsPerPage, page },
    reset
  ) => {
    const { receiveClientsSnapshot } = this.props;
    const {
      clients: { page: currentPage }
    } = this.props;
    return this.getClientsWithSearchCriteria(
      order,
      orderBy,
      rowsPerPage,
      page,
      reset
    ).onSnapshot(snapshot => {
      const snapshots = this.maybeReverseSnapshots(
        snapshot,
        page,
        currentPage,
        reset
      );
      const clients = snapshots.map(doc => ({ ...doc.data(), id: doc.id }));
      const endBefore = snapshots[0];
      const startAfter = snapshots[clients.length - 1];
      receiveClientsSnapshot(clients, endBefore, startAfter);
    });
  };

  registerClientsTotalCountSnapshotListener = () => {
    const { receiveClientsTotalCountSnapshot } = this.props;
    return this.getClients().onSnapshot(snapshot => {
      let totalCount = snapshot.size;
      receiveClientsTotalCountSnapshot(totalCount);
    });
  };

  resetData = () => {
    this.unsubscribeClientsListener();
    this.unsubscribeClientsTotalCountListener();
    this.unsubscribeClientsListener = this.registerClientsSnapshotListener(
      this.props.clients,
      true
    );
    this.unsubscribeClientsTotalCountListener = this.registerClientsTotalCountSnapshotListener();
  };

  handleRetrieveData = (searchCriteria, reset) => {
    const { setSearchCriteria } = this.props;
    this.unsubscribeClientsListener();

    this.unsubscribeClientsListener = this.registerClientsSnapshotListener(
      searchCriteria,
      reset
    );
    setSearchCriteria(searchCriteria);
  };

  handleCheckChange = name => event => {
    this.setState({ [name]: event.target.checked }, () => {
      this.props.setSearchCriteria({ page: 0 });
      this.resetData();
    });
  };

  handleFilterMenuItemClick = column => {
    this.props.setColumnVisibility(column);
  };

  handleRowClick = row => {
    this.props.history.push(`/ads/client/${row.id}`);
  };

  render() {
    const { clients, classes } = this.props;
    return (
      <div>
        <div className={classes.actions}>
          <Button disabled={true} variant="outlined" color="primary">
            Add Client
          </Button>
        </div>
        <div className={classes.content}>
          <div className={classes.tableContainer}>
            <Datatable
              title="Clients"
              data={clients}
              onFilterMenuItemClick={this.handleFilterMenuItemClick}
              onRetrieveData={this.handleRetrieveData}
              onRowClick={this.handleRowClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

Clients.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ clients }) => {
  return { clients };
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      {
        setSearchCriteria,
        receiveClientsSnapshot,
        receiveClientsTotalCountSnapshot,
        setColumnVisibility
      }
    )(Clients)
  )
);
