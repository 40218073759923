import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 1,
    marginTop: theme.spacing.unit * 2,
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
});

class CampaignOverview extends Component {
  state = {
    expanded: false
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  render() {
    const { campaignWithAds, classes } = this.props;
    const { expanded } = this.state;
    const totalImpressionsPerCycle = campaignWithAds.ads.reduce(
      (sum, ad) => sum + ad.impressionsPerCycle,
      0
    );
    const totalImpressionsCount = campaignWithAds.ads.reduce(
      (sum, ad) => sum + ad.impressionsCount,
      0
    );
    return (
      <div className={classes.root}>
        <ExpansionPanel
          expanded={expanded === "panel"}
          onChange={this.handleChange("panel")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              {campaignWithAds.campaign.name} Summary
            </Typography>
            <Typography className={classes.secondaryHeading}>
              Expand to see the ads and total impressions assigned to this
              campaign.
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Ad Type</TableCell>
                  <TableCell>Currently Active</TableCell>
                  <TableCell align="right">Max. Impressions</TableCell>
                  <TableCell align="right">Total Impressions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {campaignWithAds.ads.length === 0 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={4}>
                      <Typography variant="h6">
                        There are no ads assigned to this campaign.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  campaignWithAds.ads.map(row => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell>{row.type}</TableCell>
                        <TableCell>{row.active ? "Yes" : "No"}</TableCell>
                        <TableCell align="right">
                          {row.impressionsPerCycle}
                        </TableCell>
                        <TableCell align="right">
                          {row.impressionsCount}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
                <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell align="right">Ads Total</TableCell>
                  <TableCell align="right">
                    {totalImpressionsPerCycle}
                  </TableCell>
                  <TableCell align="right">{totalImpressionsCount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right">Bought Impressions</TableCell>
                  <TableCell align="right">
                    {campaignWithAds.campaign.impressionsPerCycle}
                  </TableCell>
                  <TableCell align="right" />
                </TableRow>
                <TableRow>
                  <TableCell align="right">Remaining Impressions</TableCell>
                  <TableCell align="right">
                    {campaignWithAds.campaign.impressionsPerCycle -
                      totalImpressionsPerCycle}
                  </TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableBody>
            </Table>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

CampaignOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  campaignWithAds: PropTypes.object.isRequired
};

export default withStyles(styles)(CampaignOverview);
