import React from "react";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  withStyles
} from "@material-ui/core";
import { Edit, Link as LinkIcon } from "@material-ui/icons";
import Highlighter from "react-highlight-words";

const SearchResultCard = ({ classes, id, label, doc, search, type }) => (
  <Card className={classes.card}>
    <CardContent>
      <Grid justify="space-between" container spacing={24}>
        <Grid item>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {label && (
              <Highlighter
                highlightClassName={classes.highlight}
                searchWords={[search]}
                autoEscape={true}
                textToHighlight={label}
              />
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="p">
            {/* <IconButton component={Link} to={`editor/${type}/${doc.url || id}`}> */}
            <IconButton
              href={`${process.env.REACT_APP_NUGL_HOST}/editor/${type}/${id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Edit />
            </IconButton>
            <IconButton
              href={`https://app.nugl.com/${type}/${id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkIcon />
            </IconButton>
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h5" component="h2">
        {doc.name && (
          <Highlighter
            highlightClassName={classes.highlight}
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={doc.name}
          />
        )}
      </Typography>
      <Typography className={classes.pos} color="textSecondary">
        {doc.formattedAddress && (
          <Highlighter
            highlightClassName={classes.highlight}
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={doc.formattedAddress}
          />
        )}
      </Typography>

      <Typography className={classes.pos} color="textSecondary">
        {doc.website && (
          <Highlighter
            highlightClassName={classes.highlight}
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={doc.website}
          />
        )}
      </Typography>
      <Typography component="p">
        {doc.description === "" || !doc.description ? (
          "No description in DB."
        ) : (
          <Highlighter
            highlightClassName={classes.highlight}
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={doc.description}
          />
        )}
      </Typography>
    </CardContent>
  </Card>
);

const styles = theme => ({
  card: {
    width: 600,
    margin: "1em"
  },
  title: {
    fontSize: 14
  },
  highlight: {
    backgroundColor: "#d5f4e6"
  }
});

export default withStyles(styles)(SearchResultCard);
