import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Datatable from "../../common/Datatable";
import CampaignDialog from "./CampaignDialog";
import {
  setSearchCriteria,
  receiveCampaignsSnapshot,
  receiveCampaignsTotalCountSnapshot,
  setColumnVisibility
} from "../../../actions/campaignActions";
import moment from "moment";
import { firestore } from "../../../firebase";

const styles = theme => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing.unit * 2
  },
  actionControl: {
    marginLeft: theme.spacing.unit
  },
  content: {
    display: "flex",
    flexDirection: "row"
  },
  tableContainer: {
    width: "100%"
  },
  tableContainerZoom: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: "calc(100% - 300px) !important"
  }
});

class Campaigns extends Component {
  state = {
    selectedCampaign: null,
    addCampaignDialog: false,
    hideFulfilled: true
  };

  componentDidMount() {
    this.unsubscribeCampaignsListener = this.registerCampaignsSnapshotListener(
      this.props.campaigns
    );
    this.unsubscribeCampaignsTotalCountListener = this.registerCampaignsTotalCountSnapshotListener();
  }

  componentWillUnmount() {
    this.unsubscribeCampaignsListener();
    this.unsubscribeCampaignsTotalCountListener();
  }

  getCampaigns = () => {
    const { hideFulfilled } = this.state;
    let query = firestore.collection("adCampaigns");
    if (hideFulfilled) {
      query = query.where("fulfilled", "==", false);
    }
    return query;
  };

  getCampaignsWithSearchCriteria = (
    order,
    orderBy,
    rowsPerPage,
    page,
    reset
  ) => {
    const {
      campaigns,
      campaigns: { endBefore, startAfter }
    } = this.props;

    let query = this.getCampaigns();

    if (!reset) {
      if (page > campaigns.page) {
        query = query.orderBy(orderBy, order).startAfter(startAfter);
      } else if (page < campaigns.page) {
        query = query
          .orderBy(orderBy, order === "asc" ? "desc" : "asc")
          .startAfter(endBefore);
      } else {
        query = query.orderBy(orderBy, order);
      }
    } else {
      query = query.orderBy(orderBy, order);
    }

    query = query.limit(rowsPerPage);

    return query;
  };

  refreshSelectedCampaign = campaigns => {
    const { selectedCampaign } = this.state;
    if (selectedCampaign) {
      const newSelectedCampaign = campaigns.filter(
        e => e.id === selectedCampaign.id
      );
      if (newSelectedCampaign.length > 0) {
        this.setState({ selectedCampaign: newSelectedCampaign[0] });
      }
    }
  };

  maybeReverseSnapshots = (snapshot, previousPage, currentPage, reset) => {
    let snapshots = snapshot.docs;
    if (!reset) {
      if (previousPage < currentPage) {
        snapshots = snapshots.reverse();
      }
    }
    return snapshots;
  };

  registerCampaignsSnapshotListener = (
    { order, orderBy, rowsPerPage, page },
    reset
  ) => {
    const { receiveCampaignsSnapshot } = this.props;
    const {
      campaigns: { page: currentPage }
    } = this.props;
    return this.getCampaignsWithSearchCriteria(
      order,
      orderBy,
      rowsPerPage,
      page,
      reset
    ).onSnapshot(snapshot => {
      const snapshots = this.maybeReverseSnapshots(
        snapshot,
        page,
        currentPage,
        reset
      );
      const campaigns = snapshots.map(doc => ({ ...doc.data(), id: doc.id }));
      const endBefore = snapshots[0];
      const startAfter = snapshots[campaigns.length - 1];
      const campaignPromises = campaigns.map(async campaign => {
        const campaignRef = firestore
          .collection("adCampaigns")
          .doc(campaign.id);
        const snapshot = await firestore
          .collection("ads")
          .where("campaignRef", "==", campaignRef)
          .get();
        const ads = snapshot.docs.map(e => ({ id: e.id, ...e.data() }));
        const adsCount = ads.length;
        const impressionsCount = ads.reduce(
          (count, ad) => (count += ad.impressionsCount),
          0
        );
        const impressionsRemainingPercent = `${(
          (1 - (impressionsCount / campaign.impressionsPerCycle || 0)) *
          100
        ).toFixed(2)}%`;
        const daysRemaining = campaign.startsOn
          ? moment(campaign.startsOn.toDate())
              .add(1, "month")
              .diff(
                moment.max(moment(), moment(campaign.startsOn.toDate())),
                "days"
              )
          : null;
        const renewalsRemaining = campaign.cyclesRemaining;
        return {
          ...campaign,
          adsCount,
          impressionsRemainingPercent,
          impressionsCount,
          daysRemaining,
          renewalsRemaining
        };
      });
      Promise.all(campaignPromises).then(campaignsResults => {
        this.refreshSelectedCampaign(campaignsResults);
        receiveCampaignsSnapshot(campaignsResults, endBefore, startAfter);
      });
    });
  };

  registerCampaignsTotalCountSnapshotListener = () => {
    const { receiveCampaignsTotalCountSnapshot } = this.props;
    return this.getCampaigns().onSnapshot(snapshot => {
      let totalCount = snapshot.size;
      receiveCampaignsTotalCountSnapshot(totalCount);
    });
  };

  resetData = () => {
    this.unsubscribeCampaignsListener();
    this.unsubscribeCampaignsTotalCountListener();
    this.unsubscribeCampaignsListener = this.registerCampaignsSnapshotListener(
      this.props.campaigns,
      true
    );
    this.unsubscribeCampaignsTotalCountListener = this.registerCampaignsTotalCountSnapshotListener();
  };

  handleRetrieveData = (searchCriteria, reset) => {
    const { setSearchCriteria } = this.props;
    this.unsubscribeCampaignsListener();

    this.unsubscribeCampaignsListener = this.registerCampaignsSnapshotListener(
      searchCriteria,
      reset
    );
    setSearchCriteria(searchCriteria);
  };

  handleCheckChange = name => event => {
    this.setState({ [name]: event.target.checked }, () => {
      this.props.setSearchCriteria({ page: 0 });
      this.resetData();
    });
  };

  handleFilterMenuItemClick = column => {
    this.props.setColumnVisibility(column);
  };

  handleRowClick = (row, isAlreadySelected) => {
    this.props.history.push(`/ads/campaign/${row.id}`);
    this.setState({ selectedCampaign: isAlreadySelected ? null : row });
  };

  handleAddCampaignDialogOpen = open => () => {
    this.setState({ addCampaignDialog: open });
  };

  handleAddCampaignDialogSave = async (campaign, onSuccess) => {
    await firestore.collection("adCampaigns").add(campaign);
    this.setState({
      campaignDialog: false
    });
    onSuccess();
  };

  render() {
    const { selectedCampaign, hideFulfilled } = this.state;
    const { campaigns, classes } = this.props;
    return (
      <div>
        <div className={classes.actions}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hideFulfilled}
                  onChange={this.handleCheckChange("hideFulfilled")}
                  value="hideFulfilled"
                />
              }
              label="Hide Fulfilled"
            />
          </FormGroup>
          <Button
            onClick={this.handleAddCampaignDialogOpen(true)}
            variant="outlined"
            color="primary"
          >
            Create Campaign
          </Button>
        </div>
        <div className={classes.content}>
          <div
            className={classNames(classes.tableContainer, {
              [classes.tableContainerZoom]: Boolean(selectedCampaign)
            })}
          >
            <Datatable
              title="Campaigns"
              data={campaigns}
              onFilterMenuItemClick={this.handleFilterMenuItemClick}
              onRetrieveData={this.handleRetrieveData}
              onRowClick={this.handleRowClick}
            />
          </div>
        </div>
        <CampaignDialog
          open={this.state.addCampaignDialog}
          onClose={this.handleAddCampaignDialogOpen(false)}
          onSave={this.handleAddCampaignDialogSave}
        />
      </div>
    );
  }
}

Campaigns.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ campaigns }) => {
  return { campaigns };
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      {
        setSearchCriteria,
        receiveCampaignsSnapshot,
        receiveCampaignsTotalCountSnapshot,
        setColumnVisibility
      }
    )(Campaigns)
  )
);
