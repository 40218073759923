import React from "react";
import debounce from "awesome-debounce-promise";
import { Link } from "react-router-dom";
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";

import search from "./util";
import SearchResultCard from "./SearchResultCard";
const searchDebounce = debounce(search, 500);

class Editor extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      selectedListing: {},
      searchString: "",
      results: []
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      loading: true
    });
    event.target.value.length > 3
      ? this.search(event.target.value)
      : this.setState({ loading: false });
  };

  search = async text => {
    const results = await searchDebounce(text);
    this.setState({
      results,
      loading: false
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Button variant="contained" component={Link} to="editor/create">
          Create a New Listing
        </Button>
        <br />
        <br />
        <Typography variant="h6">or</Typography>
        <br />
        <TextField
          className={classes.search}
          label="Search for a listing to edit."
          name="searchString"
          value={this.state.searchString}
          onChange={this.handleChange}
          placeholder="Type 4 characters or more."
          InputProps={{
            endAdornment: this.state.loading && <CircularProgress />
          }}
        />
        {this.state.results.map(result => (
          <SearchResultCard
            key={`${result.label}-search-result`}
            label={result.label}
            id={result.id}
            doc={result.value}
            search={this.state.searchString}
            type={result.type}
          />
        ))}
      </div>
    );
  }
}

const styles = () => ({
  search: {
    width: "30em"
  }
});

export default withStyles(styles)(Editor);
