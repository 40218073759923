import React from "react";
import { CircularProgress } from "@material-ui/core";
import { firestore } from "../../firebase";
import { formatHours } from "./util";
import defaultEditorState from "./defaultEditorState";

import ItemCreator from "./ItemCreator";

class ItemEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultEditorState,
      type: props.match.params.type,
      id: props.match.params.id,
      originalId: props.match.params.id
    };
  }

  async componentDidMount() {
    const listing = await (await firestore.doc(
      `${this.state.type}/${this.state.id}`
    )).get();
    if (!listing.exists) {
      // Possibly a URL rather than ID type.
      const secondTry = await (await firestore
        .collection(this.state.type)
        .where("url", "==", this.state.id)).get();
      secondTry.docs.forEach(doc => {
        //TODO: Handle urls instead of IDs, and the possibility of creating new listings
        console.log("Figure something out to do here.");
      });
    } else {
      const item = (await listing.ref.get()).data();
      const formattedHours = formatHours(item.hours);
      this.setState({
        item,
        new: item,
        loaded: true,
        newHours: formattedHours,
        oldHours: formattedHours
      });
    }
  }

  render() {
    if (!this.state.loaded) return <CircularProgress />;

    return (
      <ItemCreator
        id={this.state.id}
        type={this.state.type}
        new={this.state.new}
        old={this.state.item}
        newHours={this.state.newHours}
        oldHours={this.state.oldHours}
      />
    );
  }
}

export default ItemEditor;
