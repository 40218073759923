import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";
import GoogleApi from "../../../api/google";

const styles = () => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    top: 72,
    zIndex: 999,
    maxHeight: 400,
    overflowY: "auto",
    width: "100%"
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  }
});

function renderSectionTitle(section) {
  return (
    <div>
      <Divider />
      <ListSubheader disableSticky component="div">
        {section.section}
      </ListSubheader>
      <Divider />
    </div>
  );
}

function getSectionSuggestions(section) {
  return section.suggestions;
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  return (
    <MenuItem selected={isHighlighted} component="div">
      {suggestion.description || suggestion.suggestion}
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValue(suggestion) {
  return String(suggestion.description);
}

function getSuggestions(value, countries) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  if (inputLength <= 0) {
    return Promise.resolve([]);
  }
  return GoogleApi.autoCompleteSearch(inputValue, countries).then(results => {
    let sections = [];
    sections.push({
      suggestions: results
    });
    return Promise.resolve(sections);
  });
}

class GeoSelect extends React.Component {
  inputRef = null;
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      suggestions: []
    };
  }

  handleSuggestionsFetchRequested = countries => ({ value }) => {
    getSuggestions(value, countries).then(results => {
      this.setState({
        suggestions: results
      });
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleShouldRenderSuggestions = value => {
    return value.length > 0;
  };

  handleSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    if (sectionIndex === 0) {
      GoogleApi.geocodeSearch("place_id", suggestion.placeId).then(results => {
        if (results.length > 0) {
          this.props.onSelect({
            ...results[0],
            description: suggestion.description
          });
        }
      });
    }
  };

  handleInputClick = event => {
    this.inputRef.setSelectionRange(0, this.inputRef.value.length);
  };

  handleChange = (event, { newValue }) => {
    this.setState({
      searchText: newValue
    });
  };

  renderInput = inputProps => {
    const {
      classes,
      autoFocus,
      value,
      label,
      ref,
      placeholder,
      onChange,
      onInputClick,
      ...other
    } = inputProps;
    return (
      <TextField
        id="location"
        value={value}
        onChange={onChange}
        onClick={onInputClick}
        label={label}
        placeholder={placeholder}
        margin="normal"
        inputRef={e => {
          this.inputRef = e;
          return ref(e);
        }}
        {...other}
      />
    );
  };

  render() {
    const { countries, helperText, error, classes } = this.props;
    const { searchText } = this.state;
    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        multiSection={true}
        renderInputComponent={this.renderInput}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested(
          countries
        )}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        onSuggestionSelected={this.handleSuggestionSelected}
        shouldRenderSuggestions={this.handleShouldRenderSuggestions}
        renderSuggestionsContainer={renderSuggestionsContainer}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          autoFocus: false,
          classes,
          label: "Geolocation",
          placeholder: "City, State or Zip Code",
          error,
          helperText:
            helperText || "Start typing an address, city, state or zip code.",
          value: searchText || "",
          fullWidth: true,
          onChange: this.handleChange,
          onInputClick: this.handleInputClick
        }}
      />
    );
  }
}

GeoSelect.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GeoSelect);
