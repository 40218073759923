import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import { PlaceTypes } from "./placeTypes";

const styles = theme => ({
  editButton: {
    marginTop: theme.spacing.unit
  },
  ul: {
    marginTop: 0,
    marginBottom: 0
  },
  divider: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  },
  preview: {
    maxHeight: 220,
    maxWidth: 600,
    marginBottom: theme.spacing.unit * 2
  }
});

const ReviewPage = ({ ad, onEditClick, classes }) => {
  return (
    <Fragment>
      <Typography variant="subtitle2" gutterBottom>
        Review all of the information you have added to make sure it's correct.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Details
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Client:</b> {ad.client ? ad.client.label : ""}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Campaign:</b> {ad.campaign ? ad.campaign.label : ""}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Impressions:</b> {ad.impressions}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Cost Per Thousand (CPM):</b> ${ad.costPerThousand || 0}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Start Date:</b> {moment(ad.startDate).format("L")}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>End Date:</b> {moment(ad.endDate).format("L")}
      </Typography>
      <Button
        onClick={onEditClick(0)}
        className={classes.editButton}
        variant="outlined"
        color="secondary"
      >
        Edit
      </Button>
      <Divider className={classes.divider} />
      <Typography variant="h6" gutterBottom>
        Asset
      </Typography>
      <img
        className={classes.preview}
        src={
          Boolean(ad.fileDataUrl)
            ? ad.fileDataUrl
            : `${process.env.REACT_APP_IMGIX_GCS_HOST}/${ad.imageName}`
        }
        alt={ad.metadataTitle}
      />
      <Typography variant="body2" gutterBottom>
        <b>Type:</b> {ad.type}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Title:</b> {ad.metadataTitle}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Caption:</b> {ad.metadataCaption}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Redirect Url:</b> {ad.targetUrl}
      </Typography>
      <Button
        onClick={onEditClick(1)}
        className={classes.editButton}
        variant="outlined"
        color="secondary"
      >
        Edit
      </Button>
      <Divider className={classes.divider} />
      <Typography variant="h6" gutterBottom>
        Target
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Content Tags:</b>
      </Typography>
      {ad.contentTags && (
        <ul className={classes.ul}>
          {ad.contentTags.map(e => (
            <li>
              <Typography variant="body2" gutterBottom>
                {e.label}
              </Typography>
            </li>
          ))}
        </ul>
      )}
      <Typography variant="body2" gutterBottom>
        <b>Origins:</b>
      </Typography>
      {ad.originsEnabled && ad.origins && (
        <ul className={classes.ul}>
          {Object.keys(ad.origins)
            .filter(e => ad.origins[e])
            .map(e => (
              <li>
                <Typography variant="body2" gutterBottom>
                  {e}
                </Typography>
              </li>
            ))}
        </ul>
      )}
      <Typography variant="body2" gutterBottom>
        <b>Geo Target:</b>{" "}
        {ad.geoEnabled &&
          PlaceTypes.filter(e => e.value === ad.geoTarget.type)[0].label}
      </Typography>
      {ad.geoEnabled && (
        <ul className={classes.ul}>
          {ad.geoTarget.type === "cityLatLong" && (
            <Fragment>
              <li>
                <Typography variant="body2" gutterBottom>
                  <b>Place:</b> {ad.geoTarget.place}
                </Typography>
              </li>
              <li>
                <Typography variant="body2" gutterBottom>
                  <b>Latitude:</b> {ad.geoTarget.latLong.lat}
                </Typography>
              </li>
              <li>
                <Typography variant="body2" gutterBottom>
                  <b>Longitude:</b> {ad.geoTarget.latLong.lng}
                </Typography>
              </li>
              <li>
                <Typography variant="body2" gutterBottom>
                  <b>Radius:</b> {ad.geoTarget.radius} miles
                </Typography>
              </li>
            </Fragment>
          )}
          {ad.geoTarget.type === "region" && (
            <Fragment>
              <li>
                <Typography variant="body2" gutterBottom>
                  <b>Region:</b> {ad.geoTarget.region}
                </Typography>
              </li>
            </Fragment>
          )}
          {ad.geoTarget.type === "country" && (
            <Fragment>
              <li>
                <Typography variant="body2" gutterBottom>
                  <b>Country:</b> {ad.geoTarget.country}
                </Typography>
              </li>
            </Fragment>
          )}
        </ul>
      )}
      <Button
        onClick={onEditClick(2)}
        className={classes.editButton}
        variant="outlined"
        color="secondary"
      >
        Edit
      </Button>
      <Divider className={classes.divider} />
    </Fragment>
  );
};

ReviewPage.propTypes = {
  classes: PropTypes.object.isRequired,
  ad: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired
};

export default withStyles(styles)(ReviewPage);
