import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import uuid from "uuid/v4";
import getOrientedImage from "exif-orientation-image";

const styles = theme => ({
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  fileInput: {
    zIndex: -1,
    position: "absolute",
    display: "none"
  }
});

class UploadButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.inputElement = "";
  }

  rename = (file, name) => {
    const ext = file.name.split(".").pop();
    const newName = `${name}.${ext}`.toLowerCase();
    const blob = file.slice(0, file.size, file.type);
    let newBlob = new Blob([blob], { type: file.type });
    newBlob.lastModifiedDate = new Date();
    newBlob.name = newName;
    return newBlob;
  };

  handleUploadClick = () => {
    this.inputElement.click();
  };

  handleFileInputChange = event => {
    const randomUUID = uuid();
    const originalFileName = event.target.files[0].name;
    const file = this.rename(event.target.files[0], randomUUID);

    if (!this.hasExtension(file.name)) {
      this.props.onError({ code: "InvalidFileType" });
      return;
    }

    const reader = new FileReader();

    const _this = this;

    if (file.name.indexOf(".gif") > -1) {
      debugger;
      reader.onload = function(e) {
        const result = e.target.result;
        if (typeof _this.props.onFileLoad === "function") {
          file.originalFileName = originalFileName;
          _this.props.onFileLoad(file, result);
        }
      };
      reader.readAsDataURL(file);
    } else {
      getOrientedImage(file, (err, canvas) => {
        canvas.toBlob(blob => {
          reader.onload = (function() {
            return function(e) {
              const result = e.target.result;
              if (typeof _this.props.onFileLoad === "function") {
                blob.name = file.name;
                blob.originalFileName = originalFileName;
                _this.props.onFileLoad(blob, result);
              }
            };
          })(blob);
          reader.readAsDataURL(blob);
        });
      });
    }
  };

  hasExtension = fileName => {
    return new RegExp(
      "(" + this.props.fileTypes.join("|").replace(/\./g, "\\.") + ")$"
    ).test(fileName.toLowerCase());
  };

  render() {
    const { buttonText, classes, children, ...otherProps } = this.props;
    return (
      <Fragment>
        {children ? (
          React.cloneElement(children, {
            onClick: this.handleUploadClick
          })
        ) : (
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={this.handleUploadClick}
            {...otherProps}
          >
            {buttonText || "Upload"}
            <CloudUploadIcon className={classes.rightIcon} />
          </Button>
        )}
        <input
          type="file"
          className={classes.fileInput}
          ref={input => (this.inputElement = input)}
          name={this.props.name}
          onChange={this.handleFileInputChange}
          multiple
          accept="image/png, image/jpeg, image/gif"
        />
      </Fragment>
    );
  }
}

UploadButton.defaultProps = {
  fileTypes: [".jpg", "jpeg", ".png", ".gif"]
};

UploadButton.propTypes = {
  name: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  fileTypes: PropTypes.array.isRequired,
  onFileLoad: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};

export default withStyles(styles)(UploadButton);
