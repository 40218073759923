import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { signIn } from "../../../actions/userActions";
import logo from "../../../static/images/logo.svg";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    height: 50,
    width: 50,
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  logo: {
    height: 40,
    width: 40
  },
  form: {
    width: "100%",
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: {}
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  isValid = () => {
    let isValid = true;
    let errors = {};
    if (!this.state.email) {
      isValid = false;
      errors.email = "Email Address is Required";
    }
    if (!this.state.password) {
      isValid = false;
      errors.password = "Password is Required";
    }
    if (!isValid) {
      this.setState({ errors });
    } else {
      this.setState({ errors: {} });
    }
    return isValid;
  };

  handleSignIn = event => {
    const { state, props } = this;
    event.preventDefault();
    if (this.isValid()) {
      props.signIn(state.email, state.password);
    }
  };

  render() {
    const {
      state,
      props: { classes }
    } = this;
    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <img src={logo} className={classes.logo} alt="NUGL" />
          </Avatar>
          <Typography component="h1" variant="h5">
            Admin Tool
          </Typography>
          <form className={classes.form}>
            <TextField
              id="email"
              label="Email Address"
              type="email"
              value={state.email}
              error={state.errors.email}
              helperText={state.errors.email}
              onChange={this.handleChange("email")}
              margin="normal"
              fullWidth
            />
            <TextField
              id="password"
              label="Password"
              type="password"
              value={state.password}
              error={state.errors.password}
              helperText={state.errors.password}
              onChange={this.handleChange("password")}
              margin="normal"
              fullWidth
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleSignIn}
            >
              Sign in
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
  connect(
    null,
    { signIn }
  )(SignIn)
);
