import React, { Fragment } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Select from "../../common/Select";
import CampaignOverview from "./CampaignOverview";

const DetailsPage = ({
  ad,
  clients,
  campaigns,
  selectedCampaignWithAds,
  errors,
  onTextChange,
  onSelectChange,
  onClientDialogOpen,
  onCampaignDialogOpen
}) => {
  return (
    <Fragment>
      <Select
        id="clientId"
        value={ad.client}
        onChange={onSelectChange("client")}
        textFieldProps={{
          label: "Client",
          error: Boolean(errors.client),
          helperText:
            errors.client ||
            "Or type the name of a client that's not in the menu.",
          InputLabelProps: {
            shrink: true
          }
        }}
        placeholder="Select a client..."
        options={clients}
        noOptionsMessage={() => (
          <Button onClick={onClientDialogOpen(true)} color="secondary">
            Create a New Client
          </Button>
        )}
      />
      <Select
        id="campaignId"
        value={ad.campaign}
        onChange={onSelectChange("campaign")}
        textFieldProps={{
          label: "Campaign",
          error: Boolean(errors.campaign),
          helperText:
            errors.campaign ||
            "Or type the name of a campaign that's not in the menu.",
          InputLabelProps: {
            shrink: true
          }
        }}
        placeholder="Select a campaign..."
        options={campaigns}
        noOptionsMessage={() => (
          <Button onClick={onCampaignDialogOpen(true)} color="secondary">
            Create a New Campaign
          </Button>
        )}
      />
      {selectedCampaignWithAds && (
        <CampaignOverview campaignWithAds={selectedCampaignWithAds} />
      )}
      <TextField
        id="impressions"
        value={ad.impressions}
        onChange={onTextChange("impressions")}
        type="number"
        label="Impressions"
        placeholder="10000"
        error={Boolean(errors.impressions)}
        helperText={
          errors.impressions ||
          "The total number of times this ad will be displayed."
        }
        fullWidth
        margin="normal"
        InputProps={{
          startAdornment: <InputAdornment position="start">#</InputAdornment>
        }}
      />
      <TextField
        id="costPerThousand"
        value={ad.costPerThousand}
        onChange={onTextChange("costPerThousand")}
        type="number"
        label="Cost Per Thousand (CPM)"
        placeholder="100"
        error={Boolean(errors.costPerThousand)}
        helperText={
          errors.costPerThousand || "The client's cost per 1,000 impressions."
        }
        fullWidth
        margin="normal"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>
        }}
      />
      <TextField
        id="startDate"
        type="date"
        label="Start Date"
        value={ad.startDate}
        onChange={onTextChange("startDate")}
        error={Boolean(errors.startDate)}
        helperText={
          errors.startDate || "The date we will start showing this ad."
        }
        fullWidth
        margin="normal"
      />
      <TextField
        id="endDate"
        type="date"
        label="End Date"
        value={ad.endDate}
        onChange={onTextChange("endDate")}
        error={Boolean(errors.endDate)}
        helperText={errors.endDate || "The date we will stop showing this ad."}
        fullWidth
        margin="normal"
      />
    </Fragment>
  );
};

DetailsPage.propTypes = {
  ad: PropTypes.object.isRequired,
  clients: PropTypes.array.isRequired,
  campaigns: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onClientDialogOpen: PropTypes.func.isRequired
};

export default DetailsPage;
