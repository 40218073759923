import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import { PlaceTypes } from "../Form/placeTypes";

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2
  },
  ul: {
    marginTop: 0,
    marginBottom: 0
  },
  divider: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  editButton: {
    marginTop: theme.spacing.unit * 2
  },
  typographyWithNoOverflow: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
});

const Details = ({ ad, classes }) => {
  const TypographyWithNoOverflow = ({ children }) => (
    <Typography
      className={classes.typographyWithNoOverflow}
      variant="body2"
      gutterBottom
    >
      {children}
    </Typography>
  );
  const EditLink = props => <Link to={`/ads/${ad.id}/edit`} {...props} />;
  const CloneLink = props => <Link to={`/ads/${ad.id}/clone`} {...props} />;
  return (
    <div className={classes.root}>
      <TypographyWithNoOverflow>ID: {ad.id}</TypographyWithNoOverflow>
      <TypographyWithNoOverflow>Client: {ad.client}</TypographyWithNoOverflow>
      <TypographyWithNoOverflow>
        Campaign: {ad.campaign}
      </TypographyWithNoOverflow>
      <TypographyWithNoOverflow>
        Impressions: {ad.impressionsPerCycle}
      </TypographyWithNoOverflow>
      <TypographyWithNoOverflow>
        Cost Per Thousand (CPM): ${ad.costPerThousand || 0}
      </TypographyWithNoOverflow>
      <TypographyWithNoOverflow>
        Start Date: {moment(ad.startsOn.toDate()).format("L")}
      </TypographyWithNoOverflow>
      <TypographyWithNoOverflow>
        End Date: {moment(ad.endsOn.toDate()).format("L")}
      </TypographyWithNoOverflow>
      <Divider className={classes.divider} />
      <TypographyWithNoOverflow>Type: {ad.type}</TypographyWithNoOverflow>
      <TypographyWithNoOverflow>
        Title: {ad.metadata.title}
      </TypographyWithNoOverflow>
      <TypographyWithNoOverflow>
        Caption: {ad.metadata.caption}
      </TypographyWithNoOverflow>
      <TypographyWithNoOverflow>
        Redirect Url:{" "}
        <a
          href={ad.targetUrl}
          target="_blank"
          without="true"
          rel="noopener noreferrer"
        >
          {ad.targetUrl}
        </a>
      </TypographyWithNoOverflow>
      <Divider className={classes.divider} />
      <TypographyWithNoOverflow>Content Tags:</TypographyWithNoOverflow>
      {ad.tags && (
        <ul className={classes.ul}>
          {ad.tags.map(e => (
            <li key={e}>
              <TypographyWithNoOverflow>{e}</TypographyWithNoOverflow>
            </li>
          ))}
        </ul>
      )}
      <TypographyWithNoOverflow>Origins:</TypographyWithNoOverflow>
      {ad.origins && (
        <ul className={classes.ul}>
          {ad.origins.map(e => (
            <li key={e}>
              <TypographyWithNoOverflow>{e}</TypographyWithNoOverflow>
            </li>
          ))}
        </ul>
      )}
      <TypographyWithNoOverflow>Geo Target:</TypographyWithNoOverflow>
      {Boolean(ad.geoTarget) && (
        <ul className={classes.ul}>
          <li>
            <TypographyWithNoOverflow>
              Type:{" "}
              {PlaceTypes.filter(e => e.value === ad.geoTarget.type)[0].label}
            </TypographyWithNoOverflow>
          </li>
          {ad.geoTarget.type === "cityLatLong" && (
            <Fragment>
              <li>
                <TypographyWithNoOverflow>
                  Place: {ad.geoTarget.place}
                </TypographyWithNoOverflow>
              </li>
              <li>
                <TypographyWithNoOverflow>
                  Latitude: {ad.geoTarget.latLong.lat}
                </TypographyWithNoOverflow>
              </li>
              <li>
                <TypographyWithNoOverflow>
                  Longitude: {ad.geoTarget.latLong.lng}
                </TypographyWithNoOverflow>
              </li>
              <li>
                <TypographyWithNoOverflow>
                  Radius: {ad.geoTarget.radius} miles
                </TypographyWithNoOverflow>
              </li>
            </Fragment>
          )}
          {ad.geoTarget.type === "region" && (
            <Fragment>
              <li>
                <TypographyWithNoOverflow>
                  Region: {ad.geoTarget.region}
                </TypographyWithNoOverflow>
              </li>
            </Fragment>
          )}
          {ad.geoTarget.type === "country" && (
            <Fragment>
              <li>
                <TypographyWithNoOverflow>
                  Country: {ad.geoTarget.country}
                </TypographyWithNoOverflow>
              </li>
            </Fragment>
          )}
        </ul>
      )}

      {!ad.fulfilled && (
        <Button
          className={classes.editButton}
          fullWidth
          variant="outlined"
          component={EditLink}
        >
          Edit
        </Button>
      )}
      <Button
        className={classes.editButton}
        fullWidth
        variant="outlined"
        component={CloneLink}
      >
        Clone
      </Button>
    </div>
  );
};

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  ad: PropTypes.object.isRequired
};

export default withStyles(styles)(Details);
