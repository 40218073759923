const location = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    top: theme.spacing.unit * 6,
    // left: -368,
    zIndex: 999,
    maxHeight: 400,
    overflowY: "auto",
    width: 740,
    [theme.breakpoints.down("sm")]: {
      top: 40,
      left: 4,
      width: "calc(100% - 8px)"
    }
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  formControl: {
    display: "flex",
    flexDirection: "row",
    margin: 4,
    // marginRight: 0, with search button
    borderRadius: 0,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      flexGrow: 2
    }
  },
  textFieldRoot: {
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      flexGrow: 2,
      justifyContent: "space-between"
    }
  },
  textFieldInput: {
    color: theme.palette.grey["900"],
    // minWidth: 168 // with search button
    minWidth: 168 + 88,
    [theme.breakpoints.down("md")]: {
      minWidth: 168 + 38
    }
  },
  placeIcon: {
    fill: theme.palette.grey[300]
  },
  locationIcon: {
    fill: theme.palette.primary.main
  },
  startAdornment: {
    marginRight: 0,
    marginTop: 2
  }
});

export { location };
