import React from "react";
import { Typography } from "@material-ui/core";

import ListingInputField from "./ListingInputField";
import { Type } from "../../firebase";

const apiKey = "AIzaSyADbY_DXSxunwSxzRuFCKHdRNfeYoOepcI";
const nuglIconUrl = "http%3A%2F%2Fwww.nugl.com%2Fassets%2Fimg%2Fic-pin.svg"; // TODO: need jpg/png
const createMapUrl = (lat, long) =>
  `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${long}&zoom=15&size=400x400&maptype=hybrid&key=${apiKey}&markers=color:blue%7Cicon:${nuglIconUrl}%7C${lat},${long}`;

class GPSCoordinates extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      newLat: 0,
      oldLat: 0,
      newLong: 0,
      oldLong: 0
    };
  }

  componentDidMount() {
    const location = this.props.location || { _lat: 0, _long: 0 };
    const original = this.props.original || { _lat: 0, _long: 0 };
    this.setState({
      newLat: location._lat,
      newLong: location._long,
      oldLat: original._lat,
      oldLong: original._long
    });
  }

  pointChange = (latitude, longitude) => {
    let lat = parseFloat(latitude);
    let long = parseFloat(longitude);
    if (!lat) lat = 0;
    if (!long) long = 0;
    const newPoint = new Type.GeoPoint(lat, long);
    newPoint._lat = newPoint.latitude;
    newPoint._long = newPoint.longitude;
    this.props.update(newPoint);
    this.setState({
      newLat: newPoint.latitude,
      newLong: newPoint.longitude
    });
  };

  latChange = event => {
    const value = event.target.value;
    this.pointChange(value, this.state.newLong);
  };

  longChange = event => {
    const value = event.target.value;
    this.pointChange(this.state.newLat, value);
  };

  render() {
    const { className, location, original } = this.props;
    if (!location) return null;
    const newLat = location._lat;
    const newLong = location._long;
    const oldLat = original ? original._lat : 0;
    const oldLong = original ? original._long : 0;
    const staticMapURL = createMapUrl(newLat, newLong);
    return (
      <div>
        <img
          src={staticMapURL}
          alt="what their position on the map will be like"
        />
        <Typography color="textPrimary">
          If typing in numbers manually, you won't be able to add a '0' at the
          end due to how decimals work and how we're calculating the geopoint.
          You can get around this by typing the number after the zero and
          inserting the 0 afterwards.
        </Typography>
        <ListingInputField
          className={className}
          fieldName="Latitude"
          oldField={`${oldLat}`}
          newField={`${newLat}`}
          handleChange={this.latChange}
        />
        <ListingInputField
          className={className}
          fieldName="Longitude"
          oldField={`${oldLong}`}
          newField={`${newLong}`}
          handleChange={this.longChange}
        />
      </div>
    );
  }
}

export default GPSCoordinates;
