import * as ActionTypes from "./actionTypes";

export const setSearchCriteria = searchCriteria => {
  return {
    type: ActionTypes.SET_PROFILES_SEARCH_CRITERIA,
    searchCriteria
  };
};

export const setColumnVisibility = column => {
  return {
    type: ActionTypes.SET_PROFILES_COLUMN_VISIBILITY,
    column
  };
};

export const receiveProfilesSnapshot = (profiles, endBefore, startAfter) => {
  return {
    type: ActionTypes.RECEIVE_PROFILES_SNAPSHOT,
    profiles,
    endBefore,
    startAfter
  };
};

export const receiveProfilesTotalCountSnapshot = totalCount => {
  return {
    type: ActionTypes.RECEIVE_PROFILES_TOTAL_COUNT_SNAPSHOT,
    totalCount
  };
};
