import * as ActionTypes from "./actionTypes";

export const setSearchCriteria = searchCriteria => {
  return {
    type: ActionTypes.SET_CLIENTS_SEARCH_CRITERIA,
    searchCriteria
  };
};

export const setColumnVisibility = column => {
  return {
    type: ActionTypes.SET_CLIENTS_COLUMN_VISIBILITY,
    column
  };
};

export const receiveClientsSnapshot = (clients, endBefore, startAfter) => {
  return {
    type: ActionTypes.RECEIVE_CLIENTS_SNAPSHOT,
    clients,
    endBefore,
    startAfter
  };
};

export const receiveClientsTotalCountSnapshot = totalCount => {
  return {
    type: ActionTypes.RECEIVE_CLIENTS_TOTAL_COUNT_SNAPSHOT,
    totalCount
  };
};
