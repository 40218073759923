import { combineReducers } from "redux";
import userReducer from "./userReducer";
import adsReducer from "./adsReducer";
import campaignsReducer from "./campaignsReducer";
import clientsReducer from "./clientsReducer";
import profilesReducer from "./profilesReducer";

const rootReducer = combineReducers({
  user: userReducer,
  ads: adsReducer,
  campaigns: campaignsReducer,
  clients: clientsReducer,
  profiles: profilesReducer
});

export default rootReducer;
