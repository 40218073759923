import * as ActionTypes from "./actionTypes";
import { auth } from "../firebase";

const signInSuccess = user => {
  return {
    type: ActionTypes.SIGN_IN_SUCCESS,
    user
  };
};

const signInError = error => {
  return {
    type: ActionTypes.SIGN_IN_ERROR,
    error
  };
};

const signOutSuccess = () => {
  return {
    type: ActionTypes.SIGN_OUT_SUCCESS,
    user: null
  };
};

export const receiveUserAuthStateChange = user => {
  return {
    type: ActionTypes.RECEIVE_USER_STATE_CHANGE,
    user
  };
};

export const signIn = (email, password) => {
  return dispatch => {
    return auth
      .signInWithEmailAndPassword(email, password)
      .then(signInResponse => {
        const user = signInResponse.user;
        user.getIdTokenResult(true).then(tokenResult => {
          if (tokenResult.claims.admin) {
            dispatch(signInSuccess({ ...user, admin: true }));
          } else {
            dispatch(signInError("auth/insufficient-permissions"));
          }
        });
      })
      .catch(error => {
        dispatch(signInError(error.code));
      });
  };
};

export const signOut = () => {
  return dispatch => {
    return auth.signOut().then(() => {
      dispatch(signOutSuccess());
    });
  };
};
