import React from "react";
import debounce from "awesome-debounce-promise";
import {
  withStyles,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  Button,
  Input // Temp
} from "@material-ui/core";
import { Check, Save, Link as LinkIcon } from "@material-ui/icons";
import { firestore, storage } from "../../firebase";
import { Subtypes } from "../../constants/types";
import {
  toUrlFriendlyName,
  reverseTime,
  dayOfWeekSort,
  formatHours
} from "./util";
import defaultEditorState from "./defaultEditorState";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";

import ListingInputField from "./ListingInputField";
import LocationSearch from "./LocationSearch";
import ListingImages from "./ListingImages";
import OperatingHours from "./OperatingHours";
import GPSCoordinates from "./GPSCoordinates";

const checkforDuplicateID = async (text, type) => {
  const ex = await (await firestore.doc(
    `${type === "Retail" ? "listing" : type.toLowerCase()}s/${text}`
  )).get();
  return ex.exists;
};

const debounceCheckID = debounce(checkforDuplicateID, 500);

class ItemCreator extends React.PureComponent {
  constructor(props) {
    super(props);
    const newHours =
      props.newHours && Object.keys(props.newHours) > 0
        ? props.newHours
        : this.formatDefaultHours();
    const oldHours =
      props.oldHours && Object.keys(props.oldHours) > 0
        ? props.oldHours
        : this.formatDefaultHours();
    this.state = {
      ...defaultEditorState,
      type: props.type,
      id: props.id,
      originalId: props.id,
      new: props.new,
      item: props.old,
      newHours,
      oldHours
    };
    debugger;
  }

  formatDefaultHours = () => {
    const hours = {};
    [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday"
    ].forEach(label => {
      const from = new Date();
      from.setHours(9, 0, 0, 0);
      const to = new Date();
      to.setHours(21, 0, 0, 0);
      const open = true;
      hours[label] = {
        open,
        from,
        to
      };
    });
    return formatHours(hours);
  };

  handleChange = event => {
    const target = event.target;
    this.setState(previous => {
      return {
        new: {
          ...previous.new,
          [target.name]: target.value
        },
        saved: false,
        changed: true
      };
    });
  };

  handlePublish = event => {
    this.setState(previous => {
      return {
        new: {
          ...previous.new,
          publish: !previous.new.publish
        }
      };
    });
  };

  handleFeature = event => {
    this.setState(previous => {
      return {
        new: {
          ...previous.new,
          featured: !previous.new.featured
        }
      };
    });
  };

  handleDeleteProfile = async () => {
    await firestore.doc(`${this.state.type}/${this.state.id}`).delete();
    //window.history.go(-1); // TODO:
    alert(
      "Profile has been deleted. Could take up to a minute. Refresh page or navigate to a new page."
    );
  };

  handleChangeId = event => {
    const id = toUrlFriendlyName(event.target.value);
    this.setState({
      loading: true,
      id,
      saved: false,
      changed: true
    });
    if (id !== this.state.originalId) this.checkID(id, this.state.type);
  };

  handleChangeURL = event => {
    const url = toUrlFriendlyName(event.target.value);
    this.setState(previous => ({
      loadingURL: true,
      new: {
        ...previous.new,
        url
      },
      saved: false,
      changed: true
    }));
    if (url !== this.state.new.url) this.checkURL(url, this.state.type);
  };

  checkID = (text, type) => {
    debounceCheckID(text, type)
      .then(exists => {
        if (!exists) {
          this.setState(previous => ({
            id: text,
            loading: false,
            error: false
          }));
        } else {
          this.setState({
            error: true,
            loading: false
          });
        }
      })
      .catch(console.error);
  };

  checkURL = (text, type) => {
    debounceCheckID(text, type)
      .then(exists => {
        if (!exists) {
          this.setState(previous => ({
            url: text,
            loadingURL: false,
            error: false
          }));
        } else {
          this.setState({
            error: true,
            loadingURL: false
          });
        }
      })
      .catch(console.error);
  };

  handleSave = async () => {
    const { newHours } = this.state;
    const updatedHours = {
      monday: {},
      tuesday: {},
      wednesday: {},
      thursday: {},
      friday: {},
      saturday: {},
      sunday: {}
    };

    Object.keys(newHours).forEach(dayKey => {
      updatedHours[dayKey].from = reverseTime(newHours[dayKey].from);
      updatedHours[dayKey].to = reverseTime(newHours[dayKey].to);
      updatedHours[dayKey].open = newHours[dayKey].open;
    });

    const newListing = {
      ...this.state.new,
      hours: updatedHours
    };

    await firestore
      .doc(`${this.state.type}/${this.state.id}`)
      .set(newListing, { merge: true });

    // TODO: Handle changes to state after save. Easier for now to reload.
    this.setState(previous => ({
      item: previous.new,
      originalId: previous.id,
      saved: true,
      changed: false,
      warning: true,
      oldHours: newHours
    }));
  };

  handleHours = event => {
    const target = event.target;
    this.setState(previous => {
      const hourStrings = target.name.split(".");
      const newHours = previous.newHours;
      newHours[hourStrings[0]][hourStrings[1]] = target.value;
      return {
        newHours,
        saved: false,
        changed: true,
        update: previous.update + 1
      };
    });
  };

  handleOpenClose = event => {
    const target = event.target;
    this.setState(previous => {
      const dayStrings = target.name.split(".");
      const newHours = previous.newHours;
      newHours[dayStrings[0]][dayStrings[1]] = !newHours[dayStrings[0]][
        dayStrings[1]
      ];
      return {
        newHours,
        saved: false,
        changed: true,
        update: previous.update + 1
      };
    });
  };

  handleAddressSelection = info => {
    this.setState(previous => {
      return {
        new: {
          ...previous.new,
          address: info.formattedAddress
            ? info.formattedAddress.split(",")[0]
            : previous.new.address,
          formattedAddress: info.formattedAddress,
          country: info.address.country,
          zip: info.address.postal_code,
          location: info.location,
          state: info.address.administrative_area_level_1,
          city: info.address.locality || info.address.neighborhood
        }
      };
    });
  };

  handleGPSChange = geopoint => {
    this.setState(previous => {
      return {
        new: {
          ...previous.new,
          location: geopoint
        }
      };
    });
  };

  fileStorageRef = () => {
    const refPath = `${this.state.type}/${this.state.id}`;
    return storage.ref(refPath);
  };

  handleUploadStart = () => this.setState({ saving: true });

  handleUploadError = error =>
    this.setState({ saving: false }) && console.error(error);

  handleUploadSuccess = field => async filename => {
    const refPath = `${this.state.type}/${this.state.id}`;
    const url = await storage
      .ref(refPath)
      .child(filename)
      .getDownloadURL();
    this.setState(previous => {
      return {
        new: {
          ...previous.new,
          [field]: url
        },
        saved: false,
        changed: true,
        update: previous.update + 1
      };
    });
  };

  render() {
    const { classes } = this.props;
    const subtypes = Subtypes.find(s => s.type === this.state.new.type);
    const hoursObject = this.state.newHours;
    return (
      <div className={classes.form}>
        {/* {this.state.warning && <WarningMessage />} */}
        <Grid justify="space-between" container spacing={24}>
          <Grid item>
            <Typography
              className={classes.pos}
              variant="h5"
              color="textPrimary"
            >
              Basic Info
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="p">
              {this.state.saved && !this.state.changed && (
                <span style={{ color: "green" }}>
                  <Check /> Saved!
                </span>
              )}
              <IconButton
                href={`https://app.nugl.com/${this.state.type}/${this.state.item
                  .url || this.state.originalId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkIcon />
              </IconButton>
              Public Page
              <IconButton
                onClick={this.handleSave}
                disabled={!this.state.changed && this.state.id === ""}
                title="You need to make changes and make sure to add an ID before you can save."
              >
                <Save />
              </IconButton>
              Save
            </Typography>
          </Grid>
        </Grid>
        <hr />
        <div className={classes.logoContainer}>
          <img
            src={this.state.new.logoUrl}
            alt={this.state.new.name}
            style={{ width: 300 }}
          />
          <CustomUploadButton
            accept="image/*"
            name="logo"
            randomizeFilename
            storageRef={this.fileStorageRef()}
            onUploadStart={this.handleUploadStart}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess("logoUrl")}
            style={{
              cursor: "pointer"
            }}
          >
            <Typography color="primary" variant="button">
              Upload Profile Logo
            </Typography>
          </CustomUploadButton>
        </div>
        <div className={classes.logoContainer}>
          <img
            src={this.state.new.bannerUrl}
            alt={this.state.new.name}
            style={{ width: 600 }}
          />
          <CustomUploadButton
            accept="image/*"
            name="banner"
            randomizeFilename
            storageRef={this.fileStorageRef()}
            onUploadStart={this.handleUploadStart}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess("bannerUrl")}
            style={{
              cursor: "pointer"
            }}
          >
            <Typography color="primary" variant="button">
              Upload Profile Cover
            </Typography>
          </CustomUploadButton>
        </div>
        <Typography className={classes.pos} color="textPrimary">
          Featured Listing
        </Typography>
        <FormControlLabel
          name="featured"
          control={
            <Switch
              name="featured"
              title="Feature Listing"
              checked={!!this.state.new.featured}
              onChange={this.handleFeature}
              value={!!this.state.new.featured}
            />
          }
          label={
            <span>
              Switch in on position indicates this listing should featured.
            </span>
          }
        />
        <br />
        <br />
        <Typography className={classes.pos} color="textPrimary">
          Listing ID
        </Typography>
        <TextField
          className={classes.input}
          name="id"
          value={this.state.id}
          onChange={this.handleChangeId}
          placeholder={this.state.id}
          InputProps={{
            endAdornment: this.state.loading && <CircularProgress />
          }}
        />
        <br />
        {this.state.id !== this.state.originalId && (
          <Typography className={classes.pos} color="textSecondary">
            Previously: {this.state.originalId}
          </Typography>
        )}
        <br />
        <br />
        {/*<Typography className={classes.pos} color="textPrimary">
          URL (optional, if different from ID) (experimental)
        </Typography>
        {this.state.item.url !== this.state.new.url && (
          <Typography className={classes.pos} color="textSecondary">
            Previously: {this.state.item.url}
          </Typography>
        )}
        <TextField
          className={classes.input}
          name="url"
          value={this.state.new.url}
          onChange={this.handleChangeURL}
          placeholder={this.state.item.url || this.state.id || ""}
          InputProps={{
            endAdornment: this.state.loadingURL && <CircularProgress />
          }}
        />
        <br />*/}
        {this.state.item.url !== this.state.new.url && (
          <Typography className={classes.pos} color="textSecondary">
            Previously: {this.state.item.url || this.state.id || ""}
          </Typography>
        )}
        <br />
        <br />
        <Typography className={classes.pos} color="textPrimary">
          Name
        </Typography>
        <TextField
          className={classes.input}
          name="name"
          value={this.state.new.name}
          onChange={this.handleChange}
          placeholder={this.state.item.name}
        />
        <br />
        {this.state.item.name !== this.state.new.name && (
          <Typography className={classes.pos} color="textSecondary">
            Previously: {this.state.item.name}
          </Typography>
        )}
        <br />
        <br />
        <Typography className={classes.pos} color="textPrimary">
          Headline
        </Typography>
        <TextField
          className={classes.input}
          name="headline"
          value={this.state.new.headline}
          onChange={this.handleChange}
          placeholder={this.state.item.headline}
        />
        <br />
        {this.state.item.headline !== this.state.new.headline && (
          <Typography className={classes.pos} color="textSecondary">
            Previously: {this.state.item.headline}
          </Typography>
        )}
        <br />
        <br />
        <Typography className={classes.pos} color="textPrimary">
          Assign to User ID
        </Typography>
        <TextField
          className={classes.input}
          name="userId"
          value={this.state.new.userId}
          onChange={this.handleChange}
          disabled
          placeholder={
            this.state.item.userId ||
            "No user ID indicates this listing is not claimed."
          }
        />
        <br />
        {this.state.item.userId !== this.state.new.userId && (
          <Typography className={classes.pos} color="textSecondary">
            Previously: {this.state.item.userId}
          </Typography>
        )}
        <br />
        <br />
        <FormControlLabel
          name="publish"
          control={
            <Switch
              name="publish"
              title="Publish listing"
              checked={!!this.state.new.publish}
              onChange={this.handlePublish}
              value={!!this.state.new.publish}
            />
          }
          label={
            <span>
              Switch in on position indicates this listing should be added to
              search.
              <br />
              Only listings with addresses will show up in search results.
            </span>
          }
        />
        <br /> <br />
        <Typography className={classes.pos} color="textPrimary">
          Type
        </Typography>
        <Select
          value={this.state.new.type}
          className={classes.input}
          onChange={this.handleChange}
          inputProps={{
            name: "type",
            id: "type-simple"
          }}
        >
          <MenuItem value="Retail">Retail</MenuItem>
          <MenuItem value="Service">Service</MenuItem>
          <MenuItem value="Influencer">Influencer</MenuItem>
          <MenuItem value="Brand">Brand</MenuItem>
        </Select>
        {this.state.item.type !== this.state.new.type && (
          <Typography className={classes.pos} color="textSecondary">
            Previously:{" "}
            {this.state.item.type === "" ? "No type." : this.state.item.type}
          </Typography>
        )}
        <br />
        <br />
        <Typography className={classes.pos} color="textPrimary">
          Services (Sub type)
        </Typography>
        <Select
          multiple
          name="services"
          className={classes.input}
          value={this.state.new.services || []}
          onChange={this.handleChange}
          input={<Input id="select-multiple" />}
          //   MenuProps={MenuProps}
        >
          {subtypes &&
            subtypes.subtypes.map(name => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
        </Select>
        {this.state.item.services !== this.state.new.services && (
          <Typography className={classes.pos} color="textSecondary">
            Previously:{" "}
            {this.state.item.services === [] || !this.state.item.services
              ? "No services."
              : this.state.item.services.join(", ")}
          </Typography>
        )}
        <br />
        <br />
        <Typography className={classes.pos} color="textPrimary">
          Sales channels
        </Typography>
        <Select
          multiple
          name="channels"
          className={classes.input}
          value={this.state.new.channels || []}
          onChange={this.handleChange}
          input={<Input id="select-multiple" />}
          //   MenuProps={MenuProps}
        >
          {subtypes &&
            subtypes.channels.map(name => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
        </Select>
        {this.state.item.channels !== this.state.new.channels && (
          <Typography className={classes.pos} color="textSecondary">
            Previously:{" "}
            {this.state.item.channels === [] || !this.state.item.channels
              ? "No channels."
              : this.state.item.channels.join(", ")}
          </Typography>
        )}
        {["email", "phone", "description"].map(fieldName => {
          return (
            <ListingInputField
              key={`listing-input-${fieldName}`}
              className={classes.input}
              fieldName={fieldName}
              oldField={this.state.item[fieldName]}
              newField={this.state.new[fieldName]}
              handleChange={this.handleChange}
            />
          );
        })}
        <br />
        <br />
        <ListingImages
          bannerUrl={this.state.new.bannerUrl}
          logoUrl={this.state.new.logoUrl}
        />
        <br />
        <br />
        <Typography className={classes.pos} variant="h5" color="textPrimary">
          Operating Hours
        </Typography>
        <hr />
        <Typography className={classes.pos} color="textPrimary">
          Operating hours will not reflect what was already in the database.
          <br />
          You can check what they're set to at{" "}
          <a
            href={`https://app.nugl.com/${this.state.type}/${this.state.originalId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            the listing page
          </a>
          .
        </Typography>
        <br />
        <br />
        {Object.keys(hoursObject)
          .sort(dayOfWeekSort)
          .map(dayLabel => {
            const day = hoursObject[dayLabel];
            return (
              <OperatingHours
                key={`${dayLabel}-operating-hours`}
                day={day}
                dayLabel={dayLabel}
                handleHours={this.handleHours}
                handleOpenClose={this.handleOpenClose}
                className={classes.timeField}
              />
            );
          })}
        <br /> <br />
        <br /> <br />
        <Typography className={classes.pos} variant="h5" color="textPrimary">
          Call to Action Button
        </Typography>
        <hr />
        <ListingInputField
          className={classes.input}
          fieldName={"buttonText"}
          oldField={this.state.item.buttonText}
          newField={this.state.new.buttonText}
          handleChange={this.handleChange}
        />
        <ListingInputField
          className={classes.input}
          fieldName={"buttonURL"}
          oldField={this.state.item.buttonURL}
          newField={this.state.new.buttonURL}
          handleChange={this.handleChange}
        />
        <ListingInputField
          className={classes.input}
          fieldName={"buttonEmail"}
          oldField={this.state.item.buttonEmail}
          newField={this.state.new.buttonEmail}
          handleChange={this.handleChange}
        />
        <ListingInputField
          className={classes.input}
          fieldName={"buttonPhone"}
          oldField={this.state.item.buttonPhone}
          newField={this.state.new.buttonPhone}
          handleChange={this.handleChange}
        />
        <br />
        <br />
        <br />
        <br />
        <Typography className={classes.pos} variant="h5" color="textPrimary">
          Address / Location
        </Typography>
        <hr />
        <Typography className={classes.pos} color="textPrimary">
          Address is typically filled automatically by google's response from
          the location search bar below. You can tweak the info for display
          reasons if you like.
          <br />
        </Typography>
        <br />
        <LocationSearch handleAddressSelection={this.handleAddressSelection} />
        <GPSCoordinates
          className={classes.input}
          location={this.state.new.location}
          original={this.state.item.location}
          update={this.handleGPSChange}
        />
        <br />
        <Typography className={classes.pos} color="textSecondary">
          You can look for custom coordinates{" "}
          <a
            href="https://www.maps.ie/coordinates.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </Typography>
        <br />
        <br />
        {[
          "formattedAddress",
          "address",
          "city",
          "state",
          "region",
          "country",
          "zip"
        ].map(fieldName => {
          return (
            <ListingInputField
              key={`listing-input-${fieldName}`}
              className={classes.input}
              fieldName={fieldName}
              oldField={this.state.item[fieldName]}
              newField={this.state.new[fieldName]}
              handleChange={this.handleChange}
            />
          );
        })}
        <br />
        <br />
        <Typography className={classes.pos} variant="h5" color="textPrimary">
          Danger Zone
        </Typography>
        <hr />
        <Typography className={classes.pos} color="textPrimary">
          These actions cannot me undone.
        </Typography>
        <br />
        <Button
          onClick={this.handleDeleteProfile}
          variant={"outlined"}
          color="secondary"
        >
          Delete Profile
        </Button>
      </div>
    );
  }
}

ItemCreator.defaultProps = {
  id: "",
  type: "listings",
  old: {
    id: ""
  },
  new: {
    type: "Retail",
    services: [],
    channels: [],
    description: "",
    formattedAddress: "",
    address: "",
    city: "",
    region: "",
    state: "",
    zip: "",
    country: "",
    name: "",
    headline: "",
    url: "",
    logoUrl: "",
    bannerUrl: ""
  }
};

const styles = () => ({
  form: {
    width: 750
  },
  input: {
    minWidth: 500
  },
  timeField: {
    margin: "0 3em 0 0"
  },
  logoContainer: {
    paddingTop: 16,
    paddingBottom: 16
  }
});

export default withStyles(styles)(ItemCreator);
