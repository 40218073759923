import axios from "axios";
import { auth } from "../firebase";

export const calculateAd = id => {
  return auth.currentUser.getIdToken().then(token => {
    return axios
      .get(`${process.env.REACT_APP_API_HOST}/a/ads/${id}/calculate`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`
        }
      })
      .then(() => {
        console.log(`Successfully calculated ad`, id);
      })
      .catch(err => {
        console.error(err);
      });
  });
};
