import React, { PureComponent } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class ClientDialog extends PureComponent {
  state = {
    businessName: "",
    firstName: "",
    lastName: ""
  };
  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };
  handleSave = () => {
    this.props.onSave(this.state, () =>
      this.setState({
        businessName: "",
        firstName: "",
        lastName: ""
      })
    );
  };
  render() {
    const { open, onClose } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="client-dialog-title"
      >
        <DialogTitle id="client-dialog-title">Create Client</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To create a new client for this ad, enter a name below and click
            'Save'.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            value={this.state.businessName}
            onChange={this.handleChange("businessName")}
            id="businessName"
            label="Business Name"
            type="text"
            fullWidth
          />
          <TextField
            margin="dense"
            value={this.state.firstName}
            onChange={this.handleChange("firstName")}
            id="firstName"
            label="First Name"
            type="text"
            fullWidth
          />
          <TextField
            margin="dense"
            value={this.state.lastName}
            onChange={this.handleChange("lastName")}
            id="lastName"
            label="Last Name"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button onClick={this.handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ClientDialog;
