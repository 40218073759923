import React from "react";
import { Provider } from "react-redux";
import { render } from "react-dom";
import configureStore from "./store/configureStore";
import createHistory from "history/createBrowserHistory";
import App from "./components/App";
import { auth } from "./firebase";
import { receiveUserAuthStateChange } from "./actions/userActions";
import "./index.css";

const history = createHistory();

const store = configureStore({}, history);

auth.onAuthStateChanged(user => {
  if (user) {
    store.dispatch(receiveUserAuthStateChange(user));
  }
});

render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById("root")
);
