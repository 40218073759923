import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Select from "../../common/Select";
import GeoSelect from "../../common/GeoSelect";
import { PlaceTypes } from "./placeTypes";

const styles = theme => ({
  switchContainer: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit
  },
  formControlGroup: {
    paddingLeft: 7
  }
});

const TargetPage = ({
  ad,
  origins,
  devices,
  countries,
  tags,
  errors,
  onSelectChange,
  onGeoSelectChange,
  onCheckChange,
  onGeoTextChange,
  onOriginsCheckChange,
  onGeoSelect,
  classes
}) => {
  const geoCountry =
    ad.geoTarget && ad.geoTarget.country
      ? typeof ad.geoTarget.country === "string"
        ? countries.filter(
            e => e.value.toUpperCase() === ad.geoTarget.country.toUpperCase()
          )[0]
        : ad.geoTarget.country
      : "";
  const latLng =
    ad.geoTarget && ad.geoTarget.latLong
      ? `${ad.geoTarget.latLong.lat}, ${ad.geoTarget.latLong.lng}`
      : "";
  return (
    <Fragment>
      <Select
        value={ad.contentTags}
        onChange={onSelectChange("contentTags")}
        textFieldProps={{
          label: "Content Tags",
          InputLabelProps: {
            shrink: true
          }
        }}
        options={tags}
        placeholder="Select..."
        isMulti
      />
      <div className={classes.switchContainer}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={ad.originsEnabled}
                onChange={onCheckChange("originsEnabled")}
                color="primary"
              />
            }
            label={
              <Typography variant="subtitle2">
                Enable Origin Targeting
              </Typography>
            }
          />
        </FormGroup>
        {ad.originsEnabled && (
          <FormControl error={Boolean(errors.origins)} component="fieldset">
            <FormGroup className={classes.formControlGroup}>
              {devices.map(device => {
                return (
                  <FormControlLabel
                    key={device.key}
                    control={
                      <Checkbox
                        checked={ad.origins[device.key]}
                        onChange={onOriginsCheckChange(device.key)}
                        value={device.key}
                      />
                    }
                    label={device.description}
                  />
                );
              })}
              {origins.map(origin => {
                return (
                  <FormControlLabel
                    key={origin}
                    control={
                      <Checkbox
                        checked={ad.origins[origin]}
                        onChange={onOriginsCheckChange(origin)}
                        value={origin}
                      />
                    }
                    label={origin}
                  />
                );
              })}
            </FormGroup>
            <FormHelperText>
              {errors.origins ||
                "Select the websites where you want this ad to appear."}
            </FormHelperText>
          </FormControl>
        )}
      </div>
      <div className={classes.switchContainer}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={ad.geoEnabled}
                onChange={onCheckChange("geoEnabled")}
                color="primary"
              />
            }
            label={
              <Typography variant="subtitle2">
                Enable Geolocation Targeting
              </Typography>
            }
          />
        </FormGroup>
        {ad.geoEnabled && (
          <Fragment>
            <Select
              id="geoCountry"
              value={geoCountry}
              onChange={onGeoSelectChange("country")}
              textFieldProps={{
                label: "Country",
                error: Boolean(errors.geoCountry),
                helperText: errors.geoCountry,
                InputLabelProps: {
                  shrink: true
                }
              }}
              placeholder="Select a country..."
              options={countries}
            />
            <TextField
              id="geoPlaceType"
              select
              label="Place Type"
              value={ad.geoTarget.type}
              onChange={onGeoTextChange("type")}
              error={Boolean(errors.geoPlaceType)}
              helperText={errors.geoPlaceType || "The type of place to target."}
              fullWidth
              margin="normal"
            >
              {PlaceTypes.map(type => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
            {ad.geoTarget.type === "cityLatLong" && (
              <Fragment>
                <GeoSelect
                  disabled={!Boolean(ad.geoTarget.country)}
                  countries={[geoCountry ? geoCountry.value : "US"]}
                  onSelect={onGeoSelect}
                  error={Boolean(errors.geoLocation)}
                  helperText={errors.geoLocation}
                />
                <TextField
                  id="geoPlace"
                  disabled
                  value={ad.geoTarget.place}
                  label="Place"
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="geoLatLong"
                  disabled
                  value={latLng}
                  label="Coordinates"
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="geoRadius"
                  value={ad.geoTarget.radius}
                  onChange={onGeoTextChange("radius")}
                  type="number"
                  label="Radius"
                  placeholder="30"
                  error={Boolean(errors.geoRadius)}
                  helperText={
                    errors.geoRadius ||
                    "The distance in miles from the coordinates."
                  }
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">#</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">miles</InputAdornment>
                    )
                  }}
                />
              </Fragment>
            )}
            {ad.geoTarget.type === "region" && (
              <Fragment>
                <GeoSelect
                  disabled={!Boolean(ad.geoTarget.country)}
                  countries={[geoCountry ? geoCountry.value : "US"]}
                  onSelect={onGeoSelect}
                  error={Boolean(errors.geoLocation)}
                  helperText={errors.geoLocation}
                />
                <TextField
                  id="geoRegion"
                  disabled
                  value={ad.geoTarget.region}
                  label="State/Region"
                  fullWidth
                  margin="normal"
                />
              </Fragment>
            )}
            {ad.geoTarget.type === "country" && (
              <Fragment>
                <TextField
                  id="geoCountry"
                  disabled
                  value={geoCountry ? geoCountry.value : ""}
                  label="Country"
                  fullWidth
                  margin="normal"
                />
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

TargetPage.propTypes = {
  classes: PropTypes.object.isRequired,
  ad: PropTypes.object.isRequired,
  origins: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onCheckChange: PropTypes.func.isRequired,
  onOriginsCheckChange: PropTypes.func.isRequired
};

export default withStyles(styles)(TargetPage);
