import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import UploadButton from "../../common/UploadButton";
import { Types } from "./adTypes";

const styles = theme => ({
  uploadButton: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit
  }
});

const AssetPage = ({ ad, errors, onTextChange, onFileLoad, classes }) => {
  return (
    <Fragment>
      <TextField
        id="type"
        select
        label="Type"
        value={ad.type}
        onChange={onTextChange("type")}
        error={Boolean(errors.type)}
        helperText={errors.type || "The kind of ad."}
        fullWidth
        disabled={Boolean(ad.id)}
        margin="normal"
      >
        {Types.map(type => (
          <MenuItem key={type.value} value={type.value}>
            {type.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="uploadedFile"
        label="Uploaded File"
        value={ad.file ? ad.file.originalFileName : ""}
        placeholder="No file uploaded yet."
        error={Boolean(errors.file)}
        helperText={errors.file}
        fullWidth
        disabled
        InputLabelProps={{
          shrink: true
        }}
        margin="normal"
      />
      <UploadButton
        className={classes.uploadButton}
        buttonText="Upload File"
        onFileLoad={onFileLoad}
        disabled={Boolean(ad.id)}
      />
      <TextField
        id="metadataTitle"
        label="Image Title"
        value={ad.metadataTitle}
        onChange={onTextChange("metadataTitle")}
        error={Boolean(errors.metadataTitle)}
        helperText={errors.metadataTitle || "Title for the image."}
        fullWidth
        margin="normal"
      />
      <TextField
        id="metadataCaption"
        label="Image Caption"
        value={ad.metadataCaption}
        onChange={onTextChange("metadataCaption")}
        helperText="Caption for the image. Optional."
        fullWidth
        margin="normal"
      />
      <TextField
        id="targetUrl"
        label="Redirect URL"
        value={ad.targetUrl}
        onChange={onTextChange("targetUrl")}
        placeholder="https://"
        error={Boolean(errors.targetUrl)}
        helperText={
          errors.targetUrl ||
          "Where we redirect someone when they click on this ad."
        }
        fullWidth
        margin="normal"
      />
    </Fragment>
  );
};

AssetPage.propTypes = {
  classes: PropTypes.object.isRequired,
  ad: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onFileLoad: PropTypes.func.isRequired
};

export default withStyles(styles)(AssetPage);
