import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";

const OpenCloseToggle = ({ day, handleOpenClose, open }) => (
  <FormControlLabel
    control={
      <Switch
        name={`${day}.open`}
        checked={!!open}
        onChange={handleOpenClose}
        value={open}
      />
    }
    label={<span>{open ? "Open" : "Closed"}</span>}
  />
);

export default OpenCloseToggle;
