import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Slider from "@material-ui/lab/Slider";
import Divider from "@material-ui/core/Divider";
import Details from "./Details";
import { Typography } from "@material-ui/core";
import moment from "moment";

const styles = theme => ({
  root: {
    marginLeft: theme.spacing.unit,
    width: 300 - theme.spacing.unit,
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px 0px`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  closeButton: {
    alignSelf: "top"
  },
  previewContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing.unit
  },
  preview: {
    maxWidth: `calc(100% - ${theme.spacing.unit * 2}px);`,
    maxHeight: 200
  },
  controlContainer: {
    padding: theme.spacing.unit * 2
  },
  slider: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  calculateButton: {
    marginBottom: theme.spacing.unit * 2
  },
  actions: {
    margin: theme.spacing.unit * 2,
    marginTop: 0
  }
});

const Controls = ({
  ad,
  onActivateToggle,
  onThrottleToggle,
  onSliderChange,
  onSliderDragEnd,
  onCalculateAdClick,
  onFulfillAdClick,
  onClose,
  classes
}) => {
  return (
    <Paper className={classes.root}>
      <Typography className={classes.title} align="center" variant="h5">
        {ad.metadata.title}
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Typography>
      <div className={classes.previewContainer}>
        <img
          className={classes.preview}
          alt={ad.metadata.title}
          src={`${process.env.REACT_APP_IMGIX_GCS_HOST}/${ad.imageName}`}
        />
      </div>
      <Divider />
      {ad.fulfilled ? (
        <div className={classes.controlContainer}>
          <Typography variant="caption" align="center">
            <b>
              Fulfilled:{" "}
              {ad.updatedOn
                ? moment(ad.updatedOn.toDate()).format("L LTS")
                : "-"}
            </b>
          </Typography>
        </div>
      ) : (
        <Fragment>
          <List>
            <ListItem>
              <ListItemText primary="Active" />
              <ListItemSecondaryAction>
                <Switch onChange={onActivateToggle(ad)} checked={ad.active} />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="Throttle" />
              <ListItemSecondaryAction>
                <Switch onChange={onThrottleToggle(ad)} checked={ad.throttle} />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <Divider />
          <div className={classes.controlContainer}>
            <Typography variant="caption" gutterBottom>
              Throttle Modifier:{" "}
              {ad.throttleModifierPercent
                ? `${ad.throttleModifierPercent}%`
                : "Off"}
            </Typography>
            <Slider
              classes={{ container: classes.slider }}
              min={0}
              max={100}
              step={5}
              value={ad.throttleModifierPercent || 0}
              aria-labelledby="label"
              onChange={onSliderChange}
              onDragEnd={onSliderDragEnd}
            />
            {ad.throttleModifierPercent && (
              <Fragment>
                <Typography variant="caption" gutterBottom>
                  Estimated End Date:{" "}
                  {moment(ad.startsOn.toDate())
                    .add(
                      ad.cycleRemainingDays -
                        ad.cycleRemainingDays *
                          (ad.throttleModifierPercent / 100),
                      "days"
                    )
                    .format("L")}
                </Typography>
              </Fragment>
            )}
          </div>
          <Divider />
          <div className={classes.controlContainer}>
            <Button
              onClick={onCalculateAdClick(ad)}
              className={classes.calculateButton}
              fullWidth
              variant="outlined"
              color="primary"
            >
              Recalculate Stats
            </Button>
            <Typography variant="caption" align="center">
              Last Updated:{" "}
              {ad.updatedOn ? moment(ad.updatedOn.toDate()).calendar() : "-"}
            </Typography>
          </div>
        </Fragment>
      )}
      <Divider />
      <Details ad={ad} />
      <Divider />
      <div className={classes.controlContainer}>
        <Button
          onClick={onClose}
          fullWidth
          variant="outlined"
          color="secondary"
        >
          Save
        </Button>
      </div>
      {!ad.fulfilled && (
        <Fragment>
          <Divider />
          <div className={classes.controlContainer}>
            <Button
              onClick={onFulfillAdClick(ad)}
              className={classes.calculateButton}
              fullWidth
              variant="outlined"
              color="default"
            >
              Fulfill
            </Button>
          </div>
        </Fragment>
      )}
    </Paper>
  );
};

Controls.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Controls);
