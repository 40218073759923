import { firestore } from "../../firebase";

const search = async text => {
  const results = [];
  const brandsSnapshot = await (await firestore.collection("brands")).get();
  results.push(...searchSnapshot(brandsSnapshot, text, "brands"));
  const listingsSnapshot = await (await firestore.collection("listings")).get();
  results.push(...searchSnapshot(listingsSnapshot, text, "listings"));
  return results;
};

const quickSearch = (item, searchString, type) => {
  const search = searchString.toLowerCase();
  const data = item.data();
  const name = data.name ? data.name.toLowerCase() : "";
  const ro = {
    label: `${item.id} found in ${type}`,
    value: data,
    id: item.id,
    type
  };

  // Easy searches
  if (item.id.includes(search)) return ro;
  if (name.includes(search)) return ro;
  if (data.description && data.description.includes(search)) return ro;
  if (data.website && data.website.includes(search)) return ro;
  if (data.email && data.email.includes(search)) return ro;
  if (data.formattedAddress && data.formattedAddress.includes(search))
    return ro;

  // Slightly more annoying searches
  if (search.indexOf(" ") > -1) {
    if (
      name.includes(search.split(" ")[0] && name.includes(search.split(" ")[1]))
    )
      return ro;
  }

  // No match, filter out
  return null;
};

const searchSnapshot = (snapshot, search, type) => {
  const result = [];
  snapshot.forEach(doc => {
    result.push(quickSearch(doc, search, type));
  });
  return result.filter(f => f !== null);
};

export const toUrlFriendlyName = string => {
  let friendlyName = string
    .replace(/[^a-z0-9\s-]/gim, "")
    .replace(/[-]/gim, " ");
  let words = friendlyName.split(/\s+/g);
  friendlyName = words.join("-");
  friendlyName = friendlyName.toLowerCase();
  return friendlyName;
};

export const capitalize = word => `${word[0].toUpperCase()}${word.slice(1)}`;

const formatTime = portion => {
  if (!portion) {
    return null;
  }
  portion = portion.toDate ? portion.toDate() : portion;
  const from =
    typeof portion.getHours === "function"
      ? portion
      : portion && portion.toDate === "function"
      ? portion.toDate()
      : new Date();
  const from24 = from.getHours(); // + from.getTimezoneOffset() / 60;
  let left = from24;
  if (from24 > 24) left = from24 - 24;
  if (from24 === 24) left = 0;
  let right = from.getMinutes();
  if (right > 60) right -= 60;
  if (!portion) right = 0;
  return `${left}`.padStart(2, "0") + ":" + `${right}`.padStart(2, "0");
};
const formatHour = day => ({
  from: formatTime(day.from),
  to: formatTime(day.to),
  open: day.open ? day.open : false
});

export const formatHours = hours => {
  const returnHours = {};
  hours &&
    Object.keys(hours).forEach(hourKey => {
      returnHours[hourKey] = formatHour(hours[hourKey]);
    });
  return returnHours;
};

export const reverseTime = day => {
  let left = parseInt(day.split(":")[0]);
  let right = parseInt(day.split(":")[1]);
  const hours = new Date();
  hours.setHours(left);
  hours.setMinutes(right);
  return hours;
};

const englishDays = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6
};

export const dayOfWeekSort = (l, r) => englishDays[l] - englishDays[r];

export default search;
