import React from "react";
import { Typography } from "@material-ui/core";
import HoursInputFields from "./HoursInputFields";
import OpenCloseToggle from "./OpenCloseToggle";
import { capitalize } from "./util";

const OperatingHours = ({
  dayLabel,
  day,
  className,
  handleHours,
  handleOpenClose
}) => (
  <React.Fragment key={`${dayLabel}-fragment-operating-hours`}>
    <Typography color="textPrimary" variant="h6">
      {capitalize(dayLabel)}
    </Typography>
    <HoursInputFields
      day={dayLabel}
      from={day.from}
      to={day.to}
      handleHours={handleHours}
      className={className}
    />
    <OpenCloseToggle
      day={dayLabel}
      className={className}
      handleOpenClose={handleOpenClose}
      open={day.open}
    />
    <br />
    <br />
  </React.Fragment>
);

export default OperatingHours;
