import * as ActionTypes from "./actionTypes";

export const setSearchCriteria = searchCriteria => {
  return {
    type: ActionTypes.SET_CAMPAIGNS_SEARCH_CRITERIA,
    searchCriteria
  };
};

export const setColumnVisibility = column => {
  return {
    type: ActionTypes.SET_CAMPAIGNS_COLUMN_VISIBILITY,
    column
  };
};

export const receiveCampaignsSnapshot = (campaigns, endBefore, startAfter) => {
  return {
    type: ActionTypes.RECEIVE_CAMPAIGNS_SNAPSHOT,
    campaigns,
    endBefore,
    startAfter
  };
};

export const receiveCampaignsTotalCountSnapshot = totalCount => {
  return {
    type: ActionTypes.RECEIVE_CAMPAIGNS_TOTAL_COUNT_SNAPSHOT,
    totalCount
  };
};
