import React from "react";
import Autosuggest from "react-autosuggest";

import {
  Input,
  InputAdornment,
  FormControl,
  IconButton,
  Paper,
  Divider,
  ListSubheader,
  MenuItem,
  withStyles
} from "@material-ui/core";

import { Place as PlaceIcon } from "@material-ui/icons";

import GoogleApi from "../../api/google";
import { Type } from "../../firebase";

import { location as styles } from "./styles";

function renderSectionTitle(section) {
  return (
    <div>
      <Divider />
      <ListSubheader disableSticky component="div">
        {section.section}
      </ListSubheader>
      <Divider />
    </div>
  );
}

function getSectionSuggestions(section) {
  return section.suggestions;
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  return (
    <MenuItem selected={isHighlighted} component="div">
      {suggestion.description || suggestion.suggestion}
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValue(suggestion) {
  return String(suggestion.description);
}

function getSuggestions(value) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  if (inputLength <= 0) {
    return Promise.resolve([]);
  }
  return Promise.all([
    GoogleApi.autoCompleteSearch(inputValue, ["US", "CA"])
  ]).then(results => {
    let sections = [];
    sections.push({
      section: "Nearby",
      suggestions: results[0]
    });
    return Promise.resolve(sections);
  });
}

class LocationSearchBar extends React.Component {
  inputRef = null;
  state = {
    searchText: "",
    suggestions: []
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    getSuggestions(value).then(results => {
      this.setState({
        suggestions: results
      });
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleShouldRenderSuggestions = value => {
    return value.length > 0;
  };

  handleSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    if (sectionIndex === 0) {
      GoogleApi.geocodeSearch("place_id", suggestion.placeId).then(results => {
        if (results.length > 0) {
          this.props.handleAddressSelection(
            results.map((address, index) => {
              const point = new Type.GeoPoint(
                address.location.lat,
                address.location.lng
              );
              return {
                id: index,
                formattedAddress: address.formattedAddress,
                location: point,
                address
              };
            })[0]
          );
        }

        this.setState({ searchText: suggestion.description });
      });
    }
  };

  handleInputClick = event => {
    this.inputRef.setSelectionRange(0, this.inputRef.value.length);
  };

  handleLocationClick = event => {
    const { currentLocation, setCenterPoint } = this.props;
    setCenterPoint({
      lat: currentLocation.latitude,
      lng: currentLocation.longitude,
      city: currentLocation.city,
      region_code: currentLocation.region_code
    });
    this.setState({
      searchText: `${currentLocation.city}, ${currentLocation.region_code}`
    });
    if (this.props.location.pathname !== "/") {
      this.props.history.push("/");
    }
  };

  handleChange = (event, { newValue }) => {
    this.setState({
      searchText: newValue
    });
  };

  renderInput = inputProps => {
    const {
      classes,
      autoFocus,
      value,
      ref,
      placeholder,
      onChange,
      onLocationClick,
      onInputClick,
      ...other
    } = inputProps;
    return (
      <FormControl className={classes.formControl}>
        <Input
          id="location-term"
          disableUnderline
          placeholder="Near..."
          onChange={onChange}
          value={value}
          onClick={onInputClick}
          inputRef={e => {
            this.inputRef = e;
            return ref(e);
          }}
          classes={{
            root: classes.textFieldRoot,
            input: classes.textFieldInput
          }}
          startAdornment={
            <InputAdornment className={classes.startAdornment} position="start">
              <IconButton disabled>
                <PlaceIcon className={classes.placeIcon} />
              </IconButton>
            </InputAdornment>
          }
          {...other}
        />
      </FormControl>
    );
  };

  render() {
    const { classes } = this.props;
    const { searchText } = this.state;
    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        multiSection={true}
        renderInputComponent={this.renderInput}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        onSuggestionSelected={this.handleSuggestionSelected}
        shouldRenderSuggestions={this.handleShouldRenderSuggestions}
        renderSuggestionsContainer={renderSuggestionsContainer}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          autoFocus: false,
          classes,
          placeholder: "NUGL it",
          value: searchText || "",
          onChange: this.handleChange,
          onInputClick: this.handleInputClick,
          onLocationClick: this.handleSuggestionSelected
        }}
      />
    );
  }
}

export default withStyles(styles)(LocationSearchBar);
