import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Datatable from "../../common/Datatable";
import Controls from "./Controls";
import {
  setSearchCriteria,
  setColumnVisibility,
  receiveAdsSnapshot,
  receiveAdsTotalCountSnapshot
} from "../../../actions/adsActions";
import { calculateAd } from "../../../api/adApi";
import { firestore, FieldValue } from "../../../firebase";

const styles = theme => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing.unit * 2
  },
  actionControl: {
    marginLeft: theme.spacing.unit
  },
  content: {
    display: "flex",
    flexDirection: "row"
  },
  tableContainer: {
    width: "100%"
  },
  tableContainerZoom: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: "calc(100% - 300px) !important"
  }
});

class Ads extends Component {
  state = {
    ads: [],
    selectedAd: null,
    hideFulfilled: true
  };

  componentDidMount() {
    const initialDataPromises = [this.getClients(), this.getAdCampaigns()];
    Promise.all(initialDataPromises).then(results => {
      const clients = results[0].docs.map(e =>
        this.clientToSuggestion({ id: e.id, ...e.data() })
      );
      const adCampaigns = results[1].docs.map(e =>
        this.campaignToSuggestion({ id: e.id, ...e.data() })
      );
      this.setState({ clients, adCampaigns });
    });
    this.unsubscribeAdsListener = this.registerAdsSnapshotListener(
      this.props.ads
    );
    this.unsubscribeAdsTotalCountListener = this.registerAdsTotalCountSnapshotListener();
  }

  componentWillUnmount() {
    this.unsubscribeAdsListener();
    this.unsubscribeAdsTotalCountListener();
  }

  getAds = () => {
    const { hideFulfilled } = this.state;
    const campaignId = this.props.match.params.campaignId;
    const clientId = this.props.match.params.clientId;
    let adsQuery = firestore.collection("ads");
    if (hideFulfilled) {
      adsQuery = adsQuery.where("fulfilled", "==", false);
    }
    if (campaignId) {
      const campaignRef = firestore.collection("adCampaigns").doc(campaignId);
      return adsQuery.where("campaignRef", "==", campaignRef);
    } else if (clientId) {
      const clientRef = firestore.collection("clients").doc(clientId);
      return adsQuery.where("clientRef", "==", clientRef);
    } else {
      return adsQuery;
    }
  };

  getAdsWithSearchCriteria = (order, orderBy, rowsPerPage, page, reset) => {
    const {
      ads,
      ads: { endBefore, startAfter }
    } = this.props;

    let query = this.getAds();

    if (!reset) {
      if (page > ads.page) {
        query = query.orderBy(orderBy, order).startAfter(startAfter);
      } else if (page < ads.page) {
        query = query
          .orderBy(orderBy, order === "asc" ? "desc" : "asc")
          .startAfter(endBefore);
      } else {
        query = query.orderBy(orderBy, order);
      }
    } else {
      query = query.orderBy(orderBy, order);
    }

    query = query.limit(rowsPerPage);

    return query;
  };

  setAd = (id, newValues) =>
    firestore
      .collection("ads")
      .doc(id)
      .set(newValues, { merge: true });

  getClients = () => firestore.collection("clients").get();

  getAdCampaigns = () => firestore.collection("adCampaigns").get();

  clientToSuggestion = client => ({
    value: client.id,
    label: client.businessName
  });

  campaignToSuggestion = campaign => ({
    value: campaign.id,
    label: campaign.name
  });

  refreshSelectedAd = ads => {
    const { selectedAd } = this.state;
    if (selectedAd) {
      const newSelectedAd = ads.filter(e => e.id === selectedAd.id);
      if (newSelectedAd.length > 0) {
        this.setState({ selectedAd: newSelectedAd[0] });
      }
    }
  };

  maybeReverseSnapshots = (snapshot, previousPage, currentPage, reset) => {
    let snapshots = snapshot.docs;
    if (!reset) {
      if (previousPage < currentPage) {
        snapshots = snapshots.reverse();
      }
    }
    return snapshots;
  };

  registerAdsSnapshotListener = (
    { order, orderBy, rowsPerPage, page },
    reset
  ) => {
    const { receiveAdsSnapshot } = this.props;
    const {
      ads: { page: currentPage }
    } = this.props;
    return this.getAdsWithSearchCriteria(
      order,
      orderBy,
      rowsPerPage,
      page,
      reset
    ).onSnapshot(snapshot => {
      const snapshots = this.maybeReverseSnapshots(
        snapshot,
        page,
        currentPage,
        reset
      );
      const ads = snapshots.map(doc => ({ ...doc.data(), id: doc.id }));
      const endBefore = snapshots[0];
      const startAfter = snapshots[ads.length - 1];
      this.refreshSelectedAd(ads);
      receiveAdsSnapshot(ads, endBefore, startAfter);
    });
  };

  registerAdsTotalCountSnapshotListener = () => {
    const { receiveAdsTotalCountSnapshot } = this.props;
    return this.getAds().onSnapshot(snapshot => {
      let totalCount = snapshot.size;
      receiveAdsTotalCountSnapshot(totalCount);
    });
  };

  resetData = () => {
    this.unsubscribeAdsListener();
    this.unsubscribeAdsTotalCountListener();
    this.unsubscribeAdsListener = this.registerAdsSnapshotListener(
      this.props.ads,
      true
    );
    this.unsubscribeAdsTotalCountListener = this.registerAdsTotalCountSnapshotListener();
  };

  handleRetrieveData = (searchCriteria, reset) => {
    const { setSearchCriteria } = this.props;
    this.unsubscribeAdsListener();
    this.unsubscribeAdsListener = this.registerAdsSnapshotListener(
      searchCriteria,
      reset
    );
    setSearchCriteria(searchCriteria);
  };

  handleCheckChange = name => event => {
    this.setState({ [name]: event.target.checked }, () => {
      this.props.setSearchCriteria({ page: 0 });
      this.resetData();
    });
  };

  handleFilterMenuItemClick = column => {
    this.props.setColumnVisibility(column);
  };

  handleCloseControlsClick = () => this.setState({ selectedAd: null });

  handleRowClick = (row, isAlreadySelected) => {
    this.setState({ selectedAd: isAlreadySelected ? null : row });
  };

  handleEditClick = () => {
    this.props.history.push(`/ads/${this.state.selectedAd.id}`);
  };

  handleCalculateAdClick = ad => () => {
    calculateAd(ad.id);
  };

  handleFulfillAdClick = ad => () => {
    calculateAd(ad.id).then(() => {
      this.setAd(ad.id, { active: false, fulfilled: true });
    });
  };

  handleActivateToggle = ad => () => {
    this.setAd(ad.id, { active: !ad.active });
  };

  handleThrottleToggle = ad => () => {
    this.setAd(ad.id, { throttle: !ad.throttle });
  };

  handleSliderDragEnd = () => {
    const { selectedAd } = this.state;
    this.setAd(selectedAd.id, {
      throttleModifierPercent:
        selectedAd.throttleModifierPercent || FieldValue.delete()
    });
  };

  handleSliderChange = (event, value) => {
    const { selectedAd } = this.state;
    this.setState({
      selectedAd: { ...selectedAd, throttleModifierPercent: value }
    });
  };

  render() {
    const { selectedAd, clients, adCampaigns, hideFulfilled } = this.state;
    const { ads, classes, history } = this.props;
    return (
      <div>
        <div className={classes.actions}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hideFulfilled}
                  onChange={this.handleCheckChange("hideFulfilled")}
                  value="hideFulfilled"
                />
              }
              label="Hide Fulfilled"
            />
          </FormGroup>
          <Button
            onClick={() => history.push("/ads/create")}
            variant="outlined"
            color="primary"
            className={classes.actionControl}
          >
            Create Ad
          </Button>
        </div>
        <div className={classes.content}>
          <div
            className={classNames(classes.tableContainer, {
              [classes.tableContainerZoom]: Boolean(selectedAd)
            })}
          >
            <Datatable
              title="Ads"
              data={ads}
              onFilterMenuItemClick={this.handleFilterMenuItemClick}
              onRetrieveData={this.handleRetrieveData}
              onRowClick={this.handleRowClick}
            />
          </div>
          {selectedAd && (
            <Controls
              ad={selectedAd}
              clients={clients}
              adCampaigns={adCampaigns}
              onActivateToggle={this.handleActivateToggle}
              onThrottleToggle={this.handleThrottleToggle}
              onSliderChange={this.handleSliderChange}
              onSliderDragEnd={this.handleSliderDragEnd}
              onCalculateAdClick={this.handleCalculateAdClick}
              onFulfillAdClick={this.handleFulfillAdClick}
              onClose={this.handleCloseControlsClick}
            />
          )}
        </div>
      </div>
    );
  }
}

Ads.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ ads }) => {
  return { ads };
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      {
        setSearchCriteria,
        setColumnVisibility,
        receiveAdsSnapshot,
        receiveAdsTotalCountSnapshot
      }
    )(Ads)
  )
);
