export default {
  oldHours: {},
  newHours: {},
  loaded: false,
  loading: false,
  loadingURL: false,
  error: false,
  saved: false,
  changed: false,
  hours: {},
  update: 0,
  warning: false
};
