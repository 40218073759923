import React from "react";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";

export default {
  user: null,
  clients: {
    columns: [
      {
        id: "businessName",
        visible: true,
        numeric: false,
        sortable: true,
        export: true,
        label: "Business Name"
      },
      {
        id: "firstName",
        visible: true,
        numeric: false,
        sortable: true,
        export: true,
        label: "First Name"
      },
      {
        id: "lastName",
        visible: true,
        numeric: false,
        sortable: true,
        export: true,
        label: "Last Name"
      }
    ],
    data: [],
    order: "desc",
    orderBy: "businessName",
    rowsPerPage: 10,
    page: 0,
    totalCount: 0,
    endBefore: null,
    startAfter: null
  },
  campaigns: {
    columns: [
      {
        id: "name",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Name"
      },
      {
        id: "startsOn",
        visible: true,
        selector: row =>
          row.startsOn ? moment(row.startsOn.toDate()).format("L") : "-",
        numeric: false,
        sortable: false,
        export: true,
        label: "Start Date"
      },
      {
        id: "adsCount",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Total Ads"
      },
      {
        id: "impressionsPerCycle",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Total Bought Impressions"
      },
      {
        id: "impressionsCount",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Total Impressions Filled"
      },
      {
        id: "impressionsRemainingPercent",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Percent Remaining"
      },
      {
        id: "daysRemaining",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Days Remaining"
      },
      {
        id: "renewalsRemaining",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Months Remaining"
      }
    ],
    data: [],
    order: "desc",
    orderBy: "name",
    rowsPerPage: 10,
    page: 0,
    totalCount: 0,
    endBefore: null,
    startAfter: null
  },
  ads: {
    columns: [
      {
        id: "imageName",
        visible: false,
        selector: row => (
          <Avatar
            alt={row.metadata.title}
            src={`${process.env.REACT_APP_IMGIX_GCS_HOST}/${row.imageName}`}
          />
        ),
        numeric: false,
        sortable: false,
        export: false,
        label: "Ad"
      },
      { id: "id", visible: false, numeric: false, label: "ID" },
      {
        id: "client",
        visible: false,
        numeric: false,
        sortable: true,
        export: true,
        label: "Client"
      },
      {
        id: "campaign",
        visible: true,
        numeric: false,
        sortable: true,
        export: true,
        label: "Campaign"
      },
      {
        id: "origins",
        visible: true,
        selector: row => {
          return row.origins && row.origins.length > 0
            ? row.origins.join(", ")
            : "ALL";
        },
        numeric: false,
        sortable: false,
        export: true,
        label: "Sources"
      },
      {
        id: "metadata.title",
        visible: false,
        selector: row => row.metadata.title,
        numeric: false,
        sortable: true,
        export: true,
        label: "Title"
      },
      {
        id: "createdOn",
        visible: false,
        selector: row => moment(row.createdOn.toDate()).format("L"),
        numeric: false,
        sortable: true,
        export: true,
        label: "Created Date"
      },
      {
        id: "updatedOn",
        visible: false,
        selector: row => moment(row.updatedOn.toDate()).format("L LTS"),
        numeric: false,
        sortable: true,
        export: true,
        label: "Last Updated"
      },
      {
        id: "fulfilled",
        visible: false,
        selector: row => (row.fulfilled ? "true" : "false"),
        numeric: false,
        sortable: false,
        export: true,
        label: "Fulfilled"
      },
      {
        id: "active",
        visible: false,
        selector: row => (row.active ? "true" : "false"),
        numeric: false,
        sortable: false,
        export: true,
        label: "Active"
      },
      {
        id: "throttle",
        visible: false,
        selector: row => (row.throttle ? "true" : "false"),
        numeric: false,
        sortable: false,
        export: true,
        label: "Throttle"
      },
      {
        id: "type",
        visible: true,
        numeric: false,
        sortable: true,
        export: true,
        label: "Type"
      },
      {
        id: "targetUrl",
        visible: false,
        selector: row => (
          <a href={row.targetUrl} target="_blank" rel="noopener noreferrer">
            {row.targetUrl}
          </a>
        ),
        numeric: false,
        sortable: false,
        export: false,
        label: "Target URL"
      },
      {
        id: "startsOn",
        visible: false,
        selector: row => moment(row.startsOn.toDate()).format("L"),
        numeric: false,
        sortable: true,
        export: true,
        label: "Start Date"
      },
      {
        id: "endsOn",
        visible: false,
        selector: row => moment(row.endsOn.toDate()).format("L"),
        numeric: false,
        sortable: true,
        export: true,
        label: "End Date"
      },
      {
        id: "throttleModifierPercent",
        visible: false,
        selector: row =>
          row.throttleModifierPercent
            ? `+${row.throttleModifierPercent}%`
            : "-",
        numeric: true,
        sortable: true,
        export: true,
        label: "Throttle Modifier "
      },
      {
        id: "cyclePastDays",
        visible: false,
        numeric: true,
        sortable: true,
        export: true,
        label: "Days into Cycle"
      },
      {
        id: "cycleRemainingDays",
        visible: false,
        numeric: true,
        sortable: true,
        export: true,
        label: "Days Remaining"
      },
      {
        id: "impressionsPerCycle",
        visible: true,
        numeric: true,
        sortable: true,
        export: true,
        label: "Bought Impressions"
      },
      {
        id: "impressionsCount",
        visible: true,
        numeric: true,
        sortable: true,
        export: true,
        label: "Impressions Filled"
      },
      {
        id: "impressionsDailyAverage",
        visible: true,
        selector: row => `${row.impressionsDailyAverage.toFixed(2, 10)}`,
        numeric: true,
        sortable: true,
        export: true,
        label: "Daily Average Views"
      },
      {
        id: "impressionsDailyTarget",
        visible: false,
        numeric: true,
        sortable: true,
        export: true,
        label: "Daily Target Views"
      },
      {
        id: "impressionsEstimatedTotal",
        visible: true,
        numeric: true,
        sortable: true,
        export: true,
        label: "Estimated Monthly Views"
      },
      {
        id: "cycleImpressionRatio",
        visible: false,
        selector: row =>
          `${(1 - row.cycleImpressionRatio || 0).toFixed(3, 10)}`,
        numeric: true,
        sortable: true,
        export: true,
        label: "Impression Ratio"
      },
      {
        id: "costPerThousand",
        visible: false,
        selector: row =>
          row.costPerThousand ? `$${row.costPerThousand.toFixed(2, 10)}` : "-",
        numeric: true,
        sortable: true,
        export: true,
        label: "Cost Per Thousand (CPM)"
      },
      {
        id: "impressionsRemainingPercent",
        visible: true,
        selector: row => `${row.impressionsRemainingPercent.toFixed(2, 10)}%`,
        numeric: true,
        sortable: true,
        export: true,
        label: "Percent Remaining"
      },
      {
        id: "clicksCount",
        visible: false,
        numeric: true,
        sortable: true,
        export: true,
        label: "Clicks"
      },
      {
        id: "clickThroughRatePercent",
        visible: false,
        numeric: true,
        sortable: true,
        export: true,
        label: "Click Through Rate"
      }
    ],
    data: [],
    order: "desc",
    orderBy: "createdOn",
    rowsPerPage: 25,
    page: 0,
    totalCount: 0,
    endBefore: null,
    startAfter: null
  },
  profiles: {
    columns: [
      {
        id: "logoUrl",
        visible: true,
        selector: row => <Avatar alt={row.name} src={`${row.logoUrl}`} />,
        numeric: false,
        sortable: false,
        export: false,
        label: "Logo"
      },
      {
        id: "type",
        visible: true,
        numeric: false,
        sortable: true,
        export: true,
        label: "Type"
      },
      {
        id: "name",
        visible: true,
        numeric: false,
        sortable: true,
        export: true,
        label: "Business Name"
      },
      {
        id: "headline",
        visible: true,
        numeric: false,
        sortable: false,
        export: true,
        label: "Headline"
      }
    ],
    data: [],
    order: "desc",
    orderBy: "name",
    rowsPerPage: 10,
    page: 0,
    totalCount: 0,
    endBefore: null,
    startAfter: null
  }
};
