import * as ActionTypes from "../actions/actionTypes";
import initialState from "./initialState";

export default (state = initialState.profiles, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_PROFILES_SNAPSHOT:
      return {
        ...state,
        data: action.profiles,
        endBefore: action.endBefore,
        startAfter: action.startAfter
      };
    case ActionTypes.RECEIVE_PROFILES_TOTAL_COUNT_SNAPSHOT:
      return { ...state, totalCount: action.totalCount };
    case ActionTypes.SET_PROFILES_SEARCH_CRITERIA:
      return { ...state, ...action.searchCriteria };
    case ActionTypes.SET_PROFILES_COLUMN_VISIBILITY: {
      return {
        ...state,
        columns: state.columns.map(column => {
          if (column.id === action.column.id) {
            return { ...column, visible: action.column.visible ? false : true };
          }
          return column;
        })
      };
    }
    default:
      return state;
  }
};
