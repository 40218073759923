import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NuglTableHead from "./Head";
import NuglTableToolbar from "./Toolbar";
import { ExportToCsv } from "export-to-csv";

const styles = theme => ({
  root: {
    width: "100%"
  },
  table: {
    width: "100%"
  },
  tableWrapper: {
    overflowX: "auto"
  }
});

class NuglDatatable extends React.Component {
  state = {
    selected: null,
    filterAnchorEl: null
  };

  handleFilterMenuClick = event => {
    this.setState({ filterAnchorEl: event.currentTarget });
  };

  handleFilterMenuClose = () => {
    this.setState({ filterAnchorEl: null });
  };

  handleFilterMenuItemClick = column => {
    this.props.onFilterMenuItemClick(column);
  };

  handleDownloadClick = () => {
    const { data, columns } = this.props.data;
    const rows = [];
    data.forEach(row => {
      const newRow = {};
      columns.forEach(column => {
        if (!column.export) {
          return;
        }
        let value = row[column.id];
        if (typeof column.selector === "function") {
          value = column.selector(row);
        }
        newRow[column.label] = value;
      });
      rows.push(newRow);
    });
    const options = {
      fieldSeparator: ",",
      // filename: "",
      quoteStrings: "",
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      title: "Client Ad Report",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(rows);
  };

  handleClick = (event, id) => {
    const {
      data: { data },
      onRowClick
    } = this.props;
    const isAlreadySelected = this.state.selected === id;
    const newSelected = isAlreadySelected ? null : id;
    this.setState({ selected: newSelected });
    const selectedRow = data.filter(e => e.id === id)[0];
    onRowClick(selectedRow, isAlreadySelected);
  };

  handleRequestSort = (event, property) => {
    const {
      props: {
        data,
        data: { rowsPerPage },
        onRetrieveData
      }
    } = this;

    const orderBy = property;
    let order = "desc";

    if (data.orderBy === property && data.order === "desc") {
      order = "asc";
    }

    const searchCriteria = { order, orderBy, page: 0, rowsPerPage };

    onRetrieveData(searchCriteria, true);
  };

  handleChangePage = (event, page) => {
    const {
      data: { order, orderBy, rowsPerPage },
      onRetrieveData
    } = this.props;

    const searchCriteria = { order, orderBy, page, rowsPerPage };

    onRetrieveData(searchCriteria);
  };

  handleChangeRowsPerPage = event => {
    const {
      data: { order, orderBy },
      onRetrieveData
    } = this.props;

    const searchCriteria = {
      order,
      orderBy,
      page: 0,
      rowsPerPage: event.target.value
    };

    onRetrieveData(searchCriteria, true);
  };

  render() {
    const {
      props,
      props: { classes }
    } = this;
    const { selected, filterAnchorEl } = this.state;
    const { data, order, orderBy, rowsPerPage, page, totalCount } = props.data;
    const allColumns = props.data.columns;
    const columns = allColumns.filter(column => column.visible);
    const emptyRows = rowsPerPage - data.length;
    return (
      <Paper className={classes.root}>
        <NuglTableToolbar
          title={props.title}
          columns={allColumns}
          filterAnchorEl={filterAnchorEl}
          onFilterMenuItemClick={this.handleFilterMenuItemClick}
          onFilterMenuClose={this.handleFilterMenuClose}
          onFilterMenuClick={this.handleFilterMenuClick}
          onDownloadClick={this.handleDownloadClick}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <NuglTableHead
              columns={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {data.map(row => {
                const isSelected = selected === row.id;
                return (
                  <TableRow
                    hover
                    onClick={event => this.handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isSelected}
                  >
                    {columns.map(column => {
                      let value = row[column.id];
                      if (typeof column.selector === "function") {
                        value = column.selector(row);
                      }
                      return (
                        <TableCell key={column.id} numeric={column.numeric}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={columns.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

NuglDatatable.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onFilterMenuItemClick: PropTypes.func,
  onRetrieveData: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired
};

export default withStyles(styles)(NuglDatatable);
