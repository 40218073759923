export const PlaceTypes = [
  {
    value: "cityLatLong",
    label: "Coordinates"
  },
  {
    value: "region",
    label: "State/Region"
  },
  {
    value: "country",
    label: "Country"
  }
];
