import React from "react";
import { TextField } from "@material-ui/core";

const HoursInputFields = ({ className, from, to, handleHours, day }) => (
  <React.Fragment>
    <TextField
      name={`${day}.from`}
      value={from}
      onChange={handleHours}
      label="From"
      type="time"
      className={className}
      InputLabelProps={{
        shrink: true,
        step: 1800 // 5 min
      }}
    />{" "}
    <TextField
      name={`${day}.to`}
      value={to}
      onChange={handleHours}
      label="To"
      type="time"
      className={className}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        step: 1800 // 5 min
      }}
    />
  </React.Fragment>
);

export default HoursInputFields;
