import React, { PureComponent } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";

class CampaignDialog extends PureComponent {
  state = {
    name: "",
    startsOn: moment().format("YYYY-MM-DD"),
    impressionsPerCycle: 10000,
    costPerThousand: 0,
    cyclesRemaining: 0,
    errors: {}
  };
  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };
  isValid = () => {
    const { state: campaign } = this;
    let isValid = true;
    const errors = {};
    const setError = (name, message) => {
      isValid = false;
      errors[name] = message;
    };
    if (!campaign.name) {
      setError("name", "Name is required.");
    }
    if (campaign.impressionsPerCycle <= 0) {
      setError("impressionsPerCycle", "Impressions must be greater than 0.");
    }
    if (campaign.costPerThousand <= 0) {
      setError("costPerThousand", "CPM must be greater than 0.");
    }
    if (campaign.cyclesRemaining < 0 || campaign.cyclesRemaining > 12) {
      setError("cyclesRemaining", "Cycles remaining must be between 0 and 12.");
    }
    this.setState({ errors });
    return isValid;
  };
  handleSave = () => {
    if (this.isValid()) {
      let state = { ...this.state };
      delete state.errors;
      state.fulfilled = false;
      state.createdOn = new Date();
      state.startsOn = moment(state.startsOn).toDate();
      state.impressionsPerCycle = parseInt(state.impressionsPerCycle);
      state.cyclesRemaining = parseInt(state.cyclesRemaining);
      state.costPerThousand = parseFloat(state.costPerThousand);
      this.props.onSave(state, () => {
        this.setState({
          name: "",
          startsOn: moment().format("YYYY-MM-DD"),
          impressionsPerCycle: 10000,
          cyclesRemaining: 0,
          errors: {}
        });
        this.props.onClose();
      });
    }
  };
  render() {
    const { errors } = this.state;
    const { open, onClose } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="campaign-dialog-title"
      >
        <DialogTitle id="campaign-dialog-title">Create Campaign</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To create a new campaign for this ad, enter a name below and click
            'Save'.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            value={this.state.name}
            onChange={this.handleChange("name")}
            id="name"
            label="Campaign Name"
            type="text"
            error={Boolean(errors.name)}
            helperText={errors.name}
            fullWidth
          />
          <TextField
            id="impressionsPerCycle"
            value={this.state.impressionsPerCycle}
            onChange={this.handleChange("impressionsPerCycle")}
            type="number"
            label="Impressions"
            placeholder="10000"
            error={Boolean(errors.impressionsPerCycle)}
            helperText={errors.impressionsPerCycle}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">#</InputAdornment>
              )
            }}
          />
          <TextField
            id="costPerThousand"
            value={this.state.costPerThousand}
            onChange={this.handleChange("costPerThousand")}
            type="number"
            label="Cost Per Thousand (CPM)"
            placeholder="10"
            error={Boolean(errors.costPerThousand)}
            helperText={errors.costPerThousand}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
          <TextField
            id="startsOn"
            type="date"
            label="Start Date"
            value={this.state.startsOn}
            onChange={this.handleChange("startsOn")}
            error={Boolean(errors.startsOn)}
            helperText={errors.startsOn}
            fullWidth
            margin="normal"
          />
          <TextField
            id="cyclesRemaining"
            value={this.state.cyclesRemaining}
            onChange={this.handleChange("cyclesRemaining")}
            type="number"
            label="Recurring Months"
            placeholder="0"
            error={Boolean(errors.cyclesRemaining)}
            helperText={errors.cyclesRemaining}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">#</InputAdornment>
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button onClick={this.handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CampaignDialog;
