import React from "react";
import { Typography, TextField } from "@material-ui/core";
import { capitalize } from "./util";

const ListingInputField = ({
  className,
  fieldName,
  handleChange,
  oldField,
  newField
}) => (
  <React.Fragment>
    <br />
    <Typography color="textPrimary">{capitalize(fieldName)}</Typography>
    <TextField
      className={className}
      name={fieldName}
      value={newField || ""}
      onChange={handleChange}
      placeholder={oldField}
    />
    {oldField !== newField && (
      <Typography color="textSecondary">
        Previously: {oldField === "" ? `No ${fieldName}.` : oldField}
      </Typography>
    )}
    <br />
  </React.Fragment>
);

export default ListingInputField;
