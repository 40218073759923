import React from "react";
import { Typography } from "@material-ui/core";

class ListingImages extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      uploadedBanner: "",
      uploadedLogo: "",
      loading: false
    };
  }

  render() {
    const { bannerUrl, logoUrl } = this.props;
    if (!logoUrl && !bannerUrl) return null;
    return (
      <React.Fragment>
        <Typography>Listing Images</Typography>
      </React.Fragment>
    );
  }
}

export default ListingImages;
