export const Types = {
  RETAIL: "Retail",
  SERVICE: "Service",
  INFLUENCER: "Influencer",
  BRAND: "Brand",
  EVENT: "Event"
};

export const Subtypes = [
  {
    type: Types.RETAIL,
    subtypes: [
      "Dispensary",
      "Headshop",
      "Hydroponics & Indoor Gardening",
      "Garden Center",
      "General Merchandise",
      "Restaurant",
      "Social Club",
      "Other Retail"
    ],
    channels: ["Store Front", "Delivery", "Online"],
    amenities: [
      "Accepts Credit Card",
      "ATM",
      "Medical",
      "On-site Security",
      "Recreational",
      "Wheelchair Accessible"
    ]
  },
  {
    type: Types.SERVICE,
    subtypes: [
      "Advertising",
      "Architect",
      "Association",
      "Banking",
      "Bookkeeper",
      "Construction",
      "Consultant",
      "Cryptocurrency",
      "CPA",
      "Distributor",
      "Doctor",
      "Insurance",
      "Investor",
      "Lawyer",
      "Lobbyist",
      "Nutraceutical",
      "Manufacturer",
      "Marketing",
      "Organization",
      "Packaging",
      "Pharmaceutical",
      "Public Relations",
      "Realtor",
      "Security",
      "Testing Lab",
      "Transporter",
      "Web Developer",
      "Other Service"
    ],
    channels: ["Store Front", "Online"],
    amenities: ["Accepts Credit Card", "ATM", "On-site Security"]
  },
  {
    type: Types.INFLUENCER,
    subtypes: ["Artist", "Model", "Musician", "Other Influencer"],
    channels: ["Store Front", "Delivery", "Online"],
    amenities: ["Accepts Credit Card"]
  },
  {
    type: Types.BRAND,
    subtypes: [
      "Cannabis",
      "Cannabis Accessory",
      "Fertilizers & Nutrients",
      "Greenhouses & Related Products",
      "Grow Lights & Related Products",
      "Grow Products & Supplies",
      "Hydroponic Equipment & Products",
      "Magazine",
      "Merchandise",
      "Seed",
      "Other Product"
    ],
    channels: ["Store Front", "Delivery", "Online"],
    amenities: [
      "Accepts Credit Card",
      "ATM",
      "Medical",
      "On-site Security",
      "Recreational",
      "Wheelchair Accessible"
    ]
  }
];

export default {
  ...Types
};
