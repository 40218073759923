import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import Chart from "react-google-charts";
import { getDevices } from "../../../api/configApi";
import { firestore } from "../../../firebase";

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  charts: {
    width: "100%",
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  }
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originsByMonth: null
    };
  }

  getAds = () => firestore.collection("ads").get();

  setOriginsByMonthChart = async ads => {
    const deviceApiKeys = {};
    (await getDevices()).forEach(e => (deviceApiKeys[e.key] = e.type));

    const originKeys = ads.reduce((keys, current) => {
      return { ...keys, ...current.originsCount };
    }, {});

    const titleRow = ["Month"];

    Object.keys(originKeys).forEach(e => {
      const title = deviceApiKeys[e] || e;
      titleRow.push(title);
      originKeys[e] = 0;
    });

    const originsByMonth = ads
      .map(data => {
        const originsCount = { ...originKeys, ...data.originsCount };
        return {
          endsOn: data.endsOn.toDate(),
          endsOnLabel: moment(data.endsOn.toDate()).format("MM/YYYY"),
          data: originsCount
        };
      })
      .sort((a, b) => {
        return a.endsOn - b.endsOn;
      });

    const chartData = [
      titleRow,
      ...originsByMonth.reduce((rows, current) => {
        let keyExists = false;
        let row = [current.endsOnLabel];
        Object.keys(current.data).forEach(o => row.push(current.data[o]));
        rows.forEach(e => {
          keyExists = e[0] === row[0];
          if (keyExists) {
            const aggregatedRow = row.slice(1).map((a, i) => {
              return a + e[i + 1];
            });
            row = [current.endsOnLabel, ...aggregatedRow];
          }
        });
        if (keyExists) {
          rows.pop();
        }
        return [...rows, row];
      }, [])
    ];

    this.setState({ originsByMonth: chartData });
  };

  setImpressionsByMonthChart = ads => {
    const titleRow = ["Month", "Impressions", "Clicks"];

    const impressionsByMonth = ads.map(data => {
      return {
        startsOn: data.startsOn.toDate(),
        startsOnLabel: moment(data.startsOn.toDate()).format("MM/YYYY"),
        impressions: data.impressionsCount,
        clicks: data.clicksCount
      };
    });

    const reducedData = impressionsByMonth.reduce((data, current) => {
      if (data[current.startsOnLabel]) {
        data[current.startsOnLabel].impressions += current.impressions;
        data[current.startsOnLabel].clicks += current.clicks;
      } else {
        data[current.startsOnLabel] = {
          startsOn: current.startsOn,
          impressions: current.impressions,
          clicks: current.clicks
        };
      }
      return data;
    }, {});

    const chartData = [
      titleRow,
      ...Object.keys(reducedData)
        .sort((a, b) => {
          return reducedData[a].startsOn - reducedData[b].startsOn;
        })
        .map(e => [e, reducedData[e].impressions, reducedData[e].clicks])
    ];

    this.setState({ impressionsByMonth: chartData });
  };

  componentDidMount = async () => {
    const adsSnapshot = await this.getAds();

    let ads = adsSnapshot.docs.map(e => ({ ...e.data() }));
    try {
      await this.setOriginsByMonthChart(
        ads.filter(e => Boolean(e.originsCount))
      );
      this.setImpressionsByMonthChart(ads);
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const {
      state: { impressionsByMonth, originsByMonth },
      props: { classes }
    } = this;

    return (
      <main className={classes.main}>
        <Typography variant="h5" gutterBottom>
          Dashboard
        </Typography>
        <Paper className={classes.charts}>
          {impressionsByMonth && (
            <Chart
              width={"100%"}
              height={"300px"}
              chartType="Bar"
              loader={<div>Loading Chart</div>}
              data={impressionsByMonth}
              options={{
                chart: {
                  title: "Impressions",
                  subtitle: "by month"
                }
              }}
              rootProps={{ "data-testid": "3" }}
            />
          )}
        </Paper>
        <Paper className={classes.charts}>
          {originsByMonth && (
            <Chart
              width={"100%"}
              height={"500px"}
              chartType="Bar"
              loader={<div>Loading Chart</div>}
              data={originsByMonth}
              options={{
                chart: {
                  title: "Origins",
                  subtitle: "by month"
                }
              }}
            />
          )}
        </Paper>
      </main>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
  connect(
    null,
    null
  )(Dashboard)
);
