import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import {
  setSearchCriteria,
  setColumnVisibility,
  receiveAdsSnapshot,
  receiveAdsTotalCountSnapshot
} from "../../../actions/adsActions";
import { firestore, storage, FieldValue, Type } from "../../../firebase";
import { getOrigins, getDevices } from "../../../api/configApi";
import { Paper } from "@material-ui/core";
import DetailsPage from "./DetailsPage";
import AssetPage from "./AssetPage";
import TargetPage from "./TargetPage";
import ReviewPage from "./ReviewPage";
import ClientDialog from "./ClientDialog";
import CampaignDialog from "../Campaigns/CampaignDialog";
import isoCountries, { toAlpha2, getNames } from "i18n-iso-countries";

const styles = theme => ({
  root: {
    width: "100%"
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit
  },
  stepContent: {
    padding: theme.spacing.unit * 3,
    paddingTop: 0
  },
  actions: {
    display: "flex",
    justifyContent: "flex-start",
    padding: theme.spacing.unit * 3,
    paddingTop: 0
  },
  stepper: {
    flexBasis: "75%"
  },
  button: {
    marginRight: theme.spacing.unit
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  connectorActive: {
    "& $connectorLine": {
      borderColor: theme.palette.secondary.main
    }
  },
  connectorCompleted: {
    "& $connectorLine": {
      borderColor: theme.palette.primary.main
    }
  },
  connectorDisabled: {
    "& $connectorLine": {
      borderColor: theme.palette.grey[100]
    }
  },
  connectorLine: {
    transition: theme.transitions.create("border-color")
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

const getSteps = () => ["Details", "Asset", "Target", "Review"];

isoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

class Form extends Component {
  constructor(props) {
    super(props);
    const countries = getNames("en");
    const alpha2Countries = Object.keys(countries)
      .map(code => {
        return {
          value: toAlpha2(code),
          label: countries[code]
        };
      })
      .sort(this.compareCountries);
    this.state = {
      activeStep: 0,
      loading: false,
      clientDialog: false,
      campaignDialog: false,
      clients: [],
      campaigns: [],
      origins: [],
      countries: alpha2Countries,
      selectedCampaignWithAds: null,
      ad: {
        client: null,
        campaign: null,
        impressions: "",
        costPerThousand: null,
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment()
          .add(30, "days")
          .format("YYYY-MM-DD"),
        type: "",
        file: null,
        metadataTitle: "",
        metadataCaption: "",
        targetUrl: "",
        contentTags: [],
        originsEnabled: false,
        origins: {},
        devices: {},
        geoEnabled: false,
        geoTarget: {
          type: "",
          place: "",
          country: "",
          region: "",
          latLong: null
        }
      },
      errors: {}
    };
  }

  compareCountries = (a, b) => {
    const countryA = a.label.toUpperCase();
    const countryB = b.label.toUpperCase();
    let comparison = 0;
    if (countryA > countryB) {
      comparison = 1;
    } else if (countryA < countryB) {
      comparison = -1;
    }
    return comparison;
  };

  getAdById = id =>
    firestore
      .collection("ads")
      .doc(id)
      .get();

  getClients = () => firestore.collection("clients").get();

  getAdCampaigns = () => firestore.collection("adCampaigns").get();

  setCampaignWithAds = campaignId => {
    const campaignRef = firestore.collection("adCampaigns").doc(campaignId);
    const adsRef = firestore
      .collection("ads")
      .where("campaignRef", "==", campaignRef);
    Promise.all([campaignRef.get(), adsRef.get()]).then(results => {
      const campaignSnapshot = results[0];
      const adsSnapshot = results[1];
      const selectedCampaignWithAds = {
        campaign: { id: campaignSnapshot.id, ...campaignSnapshot.data() },
        ads: adsSnapshot.docs.map(e => ({ id: e.id, ...e.data() }))
      };
      let { ad } = this.state;
      let { campaign } = selectedCampaignWithAds;
      this.setState({
        selectedCampaignWithAds,
        ad: {
          ...ad,
          impressions: campaign.impressionsPerCycle,
          costPerThousand: campaign.costPerThousand,
          startDate: moment(campaign.startsOn.toDate()).format("YYYY-MM-DD"),
          endDate: moment(campaign.startsOn.toDate())
            .add(1, "month")
            .format("YYYY-MM-DD")
        }
      });
    });
  };

  clientToSuggestion = client => ({
    value: client.id,
    label: client.businessName
  });

  campaignToSuggestion = campaign => ({
    value: campaign.id,
    label: campaign.name
  });

  adDocumentToState = (doc, clients, campaigns) => {
    const ad = { id: doc.id, ...doc.data() };

    let adState = {
      id: ad.id,
      imageName: ad.imageName,
      client: clients.filter(e => e.label === ad.client)[0],
      campaign: campaigns.filter(e => e.label === ad.campaign)[0],
      impressions: ad.impressionsPerCycle,
      costPerThousand: ad.costPerThousand,
      file: { name: ad.imageName, originalFileName: ad.imageName },
      endDate: moment(ad.endsOn.toDate()).format("YYYY-MM-DD"),
      startDate: moment(ad.startsOn.toDate()).format("YYYY-MM-DD"),
      contentTags: ad.tags.map(e => ({ value: e, label: e })),
      targetUrl: ad.targetUrl,
      type: ad.type,
      metadataTitle: ad.metadata && ad.metadata.title ? ad.metadata.title : "",
      metadataCaption:
        ad.metadata && ad.metadata.caption ? ad.metadata.caption : "",
      originsEnabled: Boolean(ad.origins && ad.origins.length > 0),
      origins: ad.origins
        ? ad.origins.reduce((e, i) => ({ ...e, [i]: true }), {})
        : [],
      geoEnabled: Boolean(ad.geoTarget),
      geoTarget: ad.geoTarget || {
        type: "",
        place: "",
        country: "",
        region: "",
        latLong: null
      }
    };

    return adState;
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;
    const action = this.props.match.params.action;
    const promises = [
      getOrigins(),
      this.getClients(),
      this.getAdCampaigns(),
      getDevices()
    ];
    Promise.all(promises).then(results => {
      const origins = results[0];
      const clients = results[1].docs.map(e =>
        this.clientToSuggestion({ id: e.id, ...e.data() })
      );
      const campaigns = results[2].docs.map(e =>
        this.campaignToSuggestion({ id: e.id, ...e.data() })
      );
      const devices = results[3];
      this.setState({ origins, clients, campaigns, devices });
      if (id) {
        this.getAdById(id).then(doc => {
          const clone = action === "clone";
          const ad = this.adDocumentToState(doc, clients, campaigns);
          if (clone) {
            delete ad.id;
            ad.startDate = moment().format("YYYY-MM-DD");
            ad.endDate = moment()
              .add(30, "days")
              .format("YYYY-MM-DD");
            ad.clone = true;
          }
          this.setState({ ad });
        });
      }
    });
    const tags = [{ label: "Not Cannabis" }].map(suggestion => ({
      value: suggestion.label,
      label: suggestion.label
    }));
    this.setState({ tags });
  };

  handleTextChange = name => event => {
    let { ad } = this.state;
    this.setState({
      ad: { ...ad, [name]: event.target.value }
    });
  };

  handleGeoTextChange = name => event => {
    let {
      ad,
      ad: { geoTarget }
    } = this.state;
    this.setState({
      ad: { ...ad, geoTarget: { ...geoTarget, [name]: event.target.value } }
    });
  };

  handleSelectChange = name => value => {
    let { ad } = this.state;
    if (name === "campaign") {
      this.setCampaignWithAds(value.value);
    }
    this.setState({
      ad: { ...ad, [name]: value }
    });
  };

  handleGeoSelectChange = name => value => {
    let {
      ad,
      ad: { geoTarget }
    } = this.state;
    this.setState({
      ad: { ...ad, geoTarget: { ...geoTarget, [name]: value } }
    });
  };

  handleCheckChange = name => event => {
    let { ad } = this.state;
    this.setState({
      ad: { ...ad, [name]: event.target.checked }
    });
  };

  handleOriginsCheckChange = name => event => {
    let {
      ad,
      ad: { origins }
    } = this.state;
    this.setState({
      ad: { ...ad, origins: { ...origins, [name]: event.target.checked } }
    });
  };

  handleDateChange = name => value => {
    let { ad } = this.state;
    this.setState({
      ad: { ...ad, [name]: value }
    });
  };

  handleFileLoad = (file, fileDataUrl) => {
    let { ad } = this.state;
    this.setState({
      ad: { ...ad, file, fileDataUrl }
    });
  };

  handleEditClick = activeStep => () => {
    this.setState({ activeStep });
  };

  handleNext = () => {
    if (this.isValid()) {
      this.setState(state => ({
        activeStep: state.activeStep + 1
      }));
    }
  };

  handleBack = () => {
    const { activeStep } = this.state;
    if (activeStep === 0) {
      this.props.history.push("/ads");
    }
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  handleClientDialogOpen = open => () => {
    this.setState({ clientDialog: open });
  };

  handleClientDialogSave = async (client, onSuccess) => {
    let { ad, clients } = this.state;
    const createdRef = await firestore.collection("clients").add(client);
    const clientSuggestion = {
      value: createdRef.id,
      label: client.businessName
    };
    this.setState({
      clients: [clientSuggestion, ...clients],
      ad: { ...ad, client: clientSuggestion },
      clientDialog: false
    });
    onSuccess();
  };

  handleCampaignDialogOpen = open => () => {
    this.setState({ campaignDialog: open });
  };

  handleCampaignDialogSave = async (campaign, onSuccess) => {
    let { ad, campaigns } = this.state;
    const createdRef = await firestore.collection("adCampaigns").add(campaign);
    const campaignSuggestion = {
      value: createdRef.id,
      label: campaign.name
    };
    this.setState({
      campaigns: [campaignSuggestion, ...campaigns],
      ad: { ...ad, campaign: campaignSuggestion },
      campaignDialog: false
    });
    onSuccess();
  };

  handleGeoSelect = value => {
    const {
      administrative_area_level_1: region,
      location: latLong,
      description: place
    } = value;
    const {
      ad,
      ad: { geoTarget }
    } = this.state;
    console.log({ ...geoTarget, region, latLong, place });
    this.setState({
      ad: {
        ...ad,
        geoTarget: { ...geoTarget, region, latLong, place }
      }
    });
  };

  isValid = () => {
    const { ad, activeStep } = this.state;
    let isValid = true;
    const errors = {};
    const setError = (name, message) => {
      isValid = false;
      errors[name] = message;
    };
    switch (activeStep) {
      case 0:
        if (!ad.client) {
          setError("client", "Client is required.");
        }
        if (!ad.campaign) {
          setError("campaign", "Campaign is required.");
        }
        if (!ad.impressions) {
          setError("impressions", "Impressions is required.");
        } else if (ad.impressions <= 0) {
          setError("impressions", "Impressions must be greater than 0.");
        }
        if (!ad.startDate) {
          setError("startDate", "Start Date is required.");
        }
        if (!ad.endDate) {
          setError("endDate", "End Date is required.");
        } else if (moment(ad.endDate).diff(moment(), "days") <= 1) {
          setError("endDate", "End Date must after today.");
        }
        break;
      case 1:
        if (!ad.type) {
          setError("type", "Type is required.");
        }
        if (!ad.file) {
          setError("file", "File is required.");
        }
        if (!ad.metadataTitle) {
          setError("metadataTitle", "Title is required.");
        }
        if (!ad.targetUrl) {
          setError("targetUrl", "Redirect URL is required.");
        }
        break;
      case 2:
        if (ad.originsEnabled && Object.keys(ad.origins).length < 1) {
          setError("origins", "You must select at least 1 origin.");
        }
        if (ad.geoEnabled) {
          if (!ad.geoTarget.type) {
            setError("geoPlaceType", "Place Type is required.");
          } else {
            if (ad.geoTarget.type === "cityLatLong") {
              if (!ad.geoTarget.place) {
                setError("geoLocation", "Geolocation is required.");
                setError("geoPlace", "Place is required.");
              }
              if (!ad.geoTarget.latLong) {
                setError("geoLocation", "Geolocation is required.");
                setError("geoLatLong", "Coordinates are required.");
              }
              if (!ad.geoTarget.radius) {
                setError("geoRadius", "Radius is required.");
              }
            } else if (ad.geoTarget.type === "region") {
              if (!ad.geoTarget.region) {
                setError("geoLocation", "Geolocation is required.");
                setError("geoRegion", "Region is required.");
              }
            } else if (ad.geoTarget.type === "country") {
              if (!ad.geoTarget.country) {
                setError("geoLocation", "Geolocation is required.");
                setError("geoCountry", "Country is required.");
              }
            }
          }
        }
        break;
      default:
        return false;
    }

    if (!isValid) {
      this.setState({ errors });
    } else {
      this.setState({ errors: {} });
    }

    return isValid;
  };

  create = async ad => {
    this.setState({ loading: true });
    let newAdDocument = {
      active: true,
      fulfilled: false,
      campaignRef: firestore.doc(`adCampaigns/${ad.campaign.value}`),
      campaign: ad.campaign.label,
      clientRef: firestore.doc(`clients/${ad.client.value}`),
      client: ad.client.label,
      clickThroughRatePercent: "0.000",
      clicksCount: 0,
      costPerThousand: parseFloat(ad.costPerThousand),
      createdOn: new Date(),
      cycleRemainingDays: 1,
      cycleRemainingPercent: 100,
      impressionsPerCycle: parseInt(ad.impressions),
      impressionsRemainingCount: parseInt(ad.impressions),
      impressionsCount: 0,
      impressionsRemainingPercent: 100,
      impressionsDailyAverage: 0,
      impressionsDailyTarget: 0,
      impressionsEstimatedTotal: 0,
      imageName: ad.file.name,
      endsOn: moment(ad.endDate).toDate(),
      startsOn: moment(ad.startDate).toDate(),
      tags: ad.contentTags.map(e => e.value),
      targetUrl: ad.targetUrl,
      throttle: true,
      throttleModifierPercent: 0,
      type: ad.type,
      metadata: {
        title: ad.metadataTitle
      },
      originsCount: {}
    };

    if (ad.metadataCaption) {
      newAdDocument.metadata.caption = ad.metadataCaption;
    }

    if (newAdDocument.tags && newAdDocument.tags.indexOf("Not Cannabis") > -1) {
      newAdDocument.notCannabis = true;
    }

    if (ad.originsEnabled) {
      newAdDocument.origins = Object.keys(ad.origins).filter(
        e => ad.origins[e]
      );
    }

    if (ad.geoEnabled) {
      newAdDocument.geoTarget = ad.geoTarget;
      newAdDocument.geoTarget.country =
        typeof ad.geoTarget.country === "string"
          ? ad.geoTarget.country
          : ad.geoTarget.country.value;
      newAdDocument.geoTarget.geoPoint = new Type.GeoPoint(
        ad.geoTarget.latLong.lat,
        ad.geoTarget.latLong.lng
      );
    }

    const createdRef = await firestore.collection("ads").add(newAdDocument);

    if (ad.fileDataUrl) {
      const uploadedSnapshot = await storage
        .ref()
        .child(`ads/${newAdDocument.imageName}`)
        .put(ad.file);

      const filePath = uploadedSnapshot.metadata.fullPath;

      console.log("Successfully created a new ad.", createdRef.id, filePath);
    } else {
      console.log("Successfully cloned ad.", createdRef.id);
    }

    this.setState({ loading: true });

    this.props.history.push("/ads");
  };

  update = async ad => {
    if (ad.fulfilled) {
      throw new Error("Cannot edit a fulfilled ad.");
    }

    this.setState({ loading: true });

    let newAdDocument = {
      campaignRef: firestore.doc(`adCampaigns/${ad.campaign.value}`),
      campaign: ad.campaign.label,
      clientRef: firestore.doc(`clients/${ad.client.value}`),
      client: ad.client.label,
      impressionsPerCycle: parseInt(ad.impressions),
      costPerThousand: parseFloat(ad.costPerThousand),
      endsOn: moment(ad.endDate).toDate(),
      startsOn: moment(ad.startDate).toDate(),
      tags: ad.contentTags.map(e => e.value),
      targetUrl: ad.targetUrl,
      type: ad.type,
      metadata: {
        title: ad.metadataTitle
      },
      updatedOn: new Date()
    };

    if (ad.metadataCaption) {
      newAdDocument.metadata.caption = ad.metadataCaption;
    }

    if (newAdDocument.tags && newAdDocument.tags.indexOf("Not Cannabis") > -1) {
      newAdDocument.notCannabis = true;
    } else {
      newAdDocument.notCannabis = false;
    }

    if (ad.originsEnabled) {
      newAdDocument.origins = Object.keys(ad.origins).filter(
        e => ad.origins[e]
      );
    } else {
      newAdDocument.origins = [];
    }

    if (ad.geoEnabled) {
      newAdDocument.geoTarget = {
        ...ad.geoTarget,
        region: ad.geoTarget.region || "",
        country: ad.geoTarget.country ? ad.geoTarget.country.value : null,
        latLong: ad.geoTarget.latLong || null
      };
      newAdDocument.geoTarget.geoPoint = new Type.GeoPoint(
        ad.geoTarget.latLong.lat,
        ad.geoTarget.latLong.lng
      );
    } else {
      newAdDocument.geoTarget = FieldValue.delete();
    }

    await firestore
      .collection("ads")
      .doc(ad.id)
      .set(newAdDocument, { merge: true });

    console.log("Successfully updated ad.", ad.id);

    this.setState({ loading: true });

    this.props.history.push("/ads");
  };

  handleSubmit = async () => {
    const { ad } = this.state;

    const isUpdating = Boolean(ad.id);

    if (isUpdating) {
      await this.update(ad);
    } else {
      await this.create(ad);
    }
  };

  getStepContent = step => {
    const {
      ad,
      clients,
      campaigns,
      origins,
      devices,
      countries,
      tags,
      errors,
      selectedCampaignWithAds
    } = this.state;
    switch (step) {
      case 0:
        return (
          <DetailsPage
            ad={ad}
            clients={clients}
            campaigns={campaigns}
            selectedCampaignWithAds={selectedCampaignWithAds}
            errors={errors}
            onTextChange={this.handleTextChange}
            onSelectChange={this.handleSelectChange}
            onClientDialogOpen={this.handleClientDialogOpen}
            onCampaignDialogOpen={this.handleCampaignDialogOpen}
          />
        );
      case 1:
        return (
          <AssetPage
            ad={ad}
            errors={errors}
            onTextChange={this.handleTextChange}
            onSelectChange={this.handleSelectChange}
            onFileLoad={this.handleFileLoad}
          />
        );
      case 2:
        return (
          <TargetPage
            ad={ad}
            origins={origins}
            devices={devices}
            countries={countries}
            tags={tags}
            errors={errors}
            onTextChange={this.handleTextChange}
            onGeoTextChange={this.handleGeoTextChange}
            onSelectChange={this.handleSelectChange}
            onGeoSelectChange={this.handleGeoSelectChange}
            onCheckChange={this.handleCheckChange}
            onOriginsCheckChange={this.handleOriginsCheckChange}
            onGeoSelect={this.handleGeoSelect}
          />
        );
      case 3:
        return <ReviewPage ad={ad} onEditClick={this.handleEditClick} />;
      default:
        throw new RangeError();
    }
  };

  render() {
    const { classes } = this.props;
    const { ad, activeStep, loading } = this.state;
    const isUpdating = Boolean(ad.id);
    const steps = getSteps();
    const lastStep = activeStep === steps.length - 1;
    const connector = (
      <StepConnector
        classes={{
          active: classes.connectorActive,
          completed: classes.connectorCompleted,
          disabled: classes.connectorDisabled,
          line: classes.connectorLine
        }}
      />
    );
    return (
      <Paper className={classes.root}>
        <div className={classes.titleContainer}>
          <Typography variant="h5">
            {isUpdating ? "Update" : "Create"} Ad
          </Typography>
          <Stepper
            className={classes.stepper}
            activeStep={activeStep}
            connector={connector}
          >
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className={classes.stepContent}>
          {this.getStepContent(activeStep)}
        </div>
        <div className={classes.actions}>
          {activeStep === steps.length ? (
            <Button onClick={this.handleReset} className={classes.button}>
              Reset
            </Button>
          ) : (
            <Fragment>
              <Button
                disabled={loading}
                onClick={this.handleBack}
                className={classes.button}
              >
                {activeStep === 0 ? "Cancel" : "Back"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={lastStep ? this.handleSubmit : this.handleNext}
                className={classes.button}
              >
                {loading ? (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                ) : lastStep ? (
                  "Finish"
                ) : (
                  "Next"
                )}
              </Button>
            </Fragment>
          )}
        </div>
        <ClientDialog
          open={this.state.clientDialog}
          onClose={this.handleClientDialogOpen(false)}
          onSave={this.handleClientDialogSave}
        />
        <CampaignDialog
          open={this.state.campaignDialog}
          onClose={this.handleCampaignDialogOpen(false)}
          onSave={this.handleCampaignDialogSave}
        />
      </Paper>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ ads }) => {
  return { ads };
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      {
        setSearchCriteria,
        setColumnVisibility,
        receiveAdsSnapshot,
        receiveAdsTotalCountSnapshot
      }
    )(Form)
  )
);
