import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PictureInPictureIcon from "@material-ui/icons/PictureInPicture";
import GroupIcon from "@material-ui/icons/Group";
// import BarChartIcon from "@material-ui/icons/BarChart";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import StoreIcon from "@material-ui/icons/Store";
import StarIcon from "@material-ui/icons/Star";

const drawerWidth = 240;

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  toolbar: theme.mixins.toolbar
});

class NuglDrawer extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.toolbar} />
        <List>
          <ListItem button component={Link} to="/">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItem>
          <ListItem button component={Link} to="/clients">
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary={"Clients"} />
          </ListItem>
          <ListItem button component={Link} to="/campaigns">
            <ListItemIcon>
              <GroupWorkIcon />
            </ListItemIcon>
            <ListItemText primary={"Campaigns"} />
          </ListItem>
          <ListItem button component={Link} to="/ads">
            <ListItemIcon>
              <PictureInPictureIcon />
            </ListItemIcon>
            <ListItemText primary={"Ads"} />
          </ListItem>
          <ListItem button component={Link} to="/editor">
            <ListItemIcon>
              <StoreIcon />
            </ListItemIcon>
            <ListItemText primary={"Editor"} />
          </ListItem>
          <ListItem button component={Link} to="/profiles/featured">
            <ListItemIcon>
              <StarIcon />
            </ListItemIcon>
            <ListItemText primary={"Featured Profiles"} />
          </ListItem>
        </List>
      </Drawer>
    );
  }
}

NuglDrawer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NuglDrawer);
