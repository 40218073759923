import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const styles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    display: "flex",
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  }
});

const NuglToolbar = props => {
  const {
    title,
    columns,
    filterAnchorEl,
    onFilterMenuItemClick,
    onFilterMenuClose,
    onFilterMenuClick,
    onDownloadClick,
    classes
  } = props;
  const filterMenuOpen = Boolean(filterAnchorEl);
  return (
    <Fragment>
      <Toolbar className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h6" id="tableTitle">
            {title}
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <Tooltip title="Download">
            <IconButton aria-label="Download" onClick={onDownloadClick}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list" onClick={onFilterMenuClick}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="filter-menu"
            anchorEl={filterAnchorEl}
            open={filterMenuOpen}
            onClose={onFilterMenuClose}
          >
            {columns.map(column => (
              <MenuItem key={column.id}>
                <Checkbox
                  checked={column.visible}
                  tabIndex={-1}
                  disableRipple
                  onChange={() => onFilterMenuItemClick(column)}
                />
                <ListItemText primary={column.label} />
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Toolbar>
      <Divider />
    </Fragment>
  );
};

NuglToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  filterAnchorEl: PropTypes.object,
  onFilterMenuItemClick: PropTypes.func,
  onFilterMenuClose: PropTypes.func.isRequired,
  onFilterMenuClick: PropTypes.func.isRequired
};

export default withStyles(styles)(NuglToolbar);
