// user
export const RECEIVE_USER_STATE_CHANGE = "RECEIVE_USER_STATE_CHANGE";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";

// ads
export const RECEIVE_ADS_SNAPSHOT = "RECEIVE_ADS_SNAPSHOT";
export const RECEIVE_ADS_TOTAL_COUNT_SNAPSHOT =
  "RECEIVE_ADS_TOTAL_COUNT_SNAPSHOT";
export const SET_ADS_SEARCH_CRITERIA = "SET_ADS_SEARCH_CRITERIA";
export const SET_ADS_COLUMN_VISIBILITY = "SET_ADS_COLUMN_VISIBILITY";

// campaigns
export const RECEIVE_CAMPAIGNS_SNAPSHOT = "RECEIVE_CAMPAIGNS_SNAPSHOT";
export const RECEIVE_CAMPAIGNS_TOTAL_COUNT_SNAPSHOT =
  "RECEIVE_CAMPAIGNS_TOTAL_COUNT_SNAPSHOT";
export const SET_CAMPAIGNS_SEARCH_CRITERIA = "SET_CAMPAIGNS_SEARCH_CRITERIA";
export const SET_CAMPAIGNS_COLUMN_VISIBILITY =
  "SET_CAMPAIGNS_COLUMN_VISIBILITY";

// clients
export const RECEIVE_CLIENTS_SNAPSHOT = "RECEIVE_CLIENTS_SNAPSHOT";
export const RECEIVE_CLIENTS_TOTAL_COUNT_SNAPSHOT =
  "RECEIVE_CLIENTS_TOTAL_COUNT_SNAPSHOT";
export const SET_CLIENTS_SEARCH_CRITERIA = "SET_CLIENTS_SEARCH_CRITERIA";
export const SET_CLIENTS_COLUMN_VISIBILITY = "SET_CLIENTS_COLUMN_VISIBILITY";

// profiles
export const RECEIVE_PROFILES_SNAPSHOT = "RECEIVE_PROFILES_SNAPSHOT";
export const RECEIVE_PROFILES_TOTAL_COUNT_SNAPSHOT =
  "RECEIVE_PROFILES_TOTAL_COUNT_SNAPSHOT";
export const SET_PROFILES_SEARCH_CRITERIA = "SET_PROFILES_SEARCH_CRITERIA";
export const SET_PROFILES_COLUMN_VISIBILITY = "SET_PROFILES_COLUMN_VISIBILITY";
