import axios from "axios";

const env =
  process.env.REACT_APP_API_HOST ||
  "https://us-central1-nugl-dev.cloudfunctions.net";

export default class GoogleApi {
  static geocodeSearch(type, input) {
    const searchText = input.split(" ").join("+");
    return axios
      .get(`${env}/google/geocode`, {
        params: {
          type,
          input: searchText
        }
        //headers: {'Authentication': `Bearer ${token}`}
      })
      .then(response => {
        return response.data;
      });
  }
  static timezoneSearch(latitude, longitude) {
    return axios
      .get(`${env}/google/timezone`, {
        params: {
          location: `${latitude},${longitude}`,
          timestamp: new Date().getTime() / 1000
        }
      })
      .then(response => {
        return response.data;
      });
  }
  static autoCompleteSearch(input, countries) {
    const searchText = input.split(" ").join("+");
    const countriesText = countries.join(",");
    return axios
      .get(`${env}/google/autocomplete`, {
        params: {
          input: searchText,
          countries: countriesText
        }
      })
      .then(response => {
        return Promise.resolve(response.data);
      });
  }
}
