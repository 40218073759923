import React, { Component } from "react";
import { connect } from "react-redux";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Router, Route, Switch } from "react-router-dom";
import AppLayout from "./common/AppLayout";
import SignIn from "./auth/SignIn";
import Dashboard from "./home/Dashboard";
import Clients from "./clients/Clients";
import Campaigns from "./ads/Campaigns";
import Ads from "./ads/Ads";
import AdForm from "./ads/Form";
import Editor from "./editor/Editor";
import ItemEditor from "./editor/ItemEditor";
import ItemCreator from "./editor/ItemCreator";
import FeaturedProfiles from "./profiles/Profiles";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: "#00A1E4",
      light: "#2EB2E8",
      dark: "#0084BB",
      contrastColor: "#FFFFFF"
    },
    secondary: {
      main: "#EB008B",
      light: "#EE2EA0",
      dark: "#C10072",
      contrastColor: "#FFFFFF"
    }
  },
  overrides: {
    MuiButton: {
      root: {},
      raised: {},
      outlined: {},
      raisedPrimary: {
        color: "#FFFFFF"
      },
      raisedSecondary: {
        color: "#FFFFFF"
      }
    },
    MuiStepIcon: {
      text: {
        fill: "#FFFFFF"
      }
    }
  }
});

class App extends Component {
  render() {
    const { props } = this;
    return (
      <MuiThemeProvider theme={theme}>
        <Router history={props.history}>
          {props.user ? (
            <AppLayout>
              <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/clients" component={Clients} />
                <Route exact path="/campaigns" component={Campaigns} />
                <Route exact path="/ads" component={Ads} />
                <Route exact path="/ads/campaign/:campaignId" component={Ads} />
                <Route exact path="/ads/client/:clientId" component={Ads} />
                <Route exact path="/ads/create" component={AdForm} />
                <Route path="/ads/:id/:action(clone|edit)" component={AdForm} />
                <Route exact path="/editor" component={Editor} />
                <Route exact path="/editor/create" component={ItemCreator} />
                <Route exact path="/editor/:type/:id" component={ItemEditor} />
                <Route
                  exact
                  path="/profiles/featured"
                  component={FeaturedProfiles}
                />
              </Switch>
            </AppLayout>
          ) : (
            <Switch>
              <Route path="/" exact component={SignIn} />
            </Switch>
          )}
        </Router>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(
  mapStateToProps,
  null
)(App);
