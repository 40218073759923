import * as ActionTypes from "../actions/actionTypes";
import initialState from "./initialState";

export default (state = initialState.user, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_USER_STATE_CHANGE:
    case ActionTypes.SIGN_IN_SUCCESS:
      const {
        user: { displayName, email, phoneNumber, photoURL, uid, metadata }
      } = action;
      return {
        creationTime: metadata.creationTime,
        displayName,
        email,
        phoneNumber,
        photoURL: photoURL || "https://nugl.imgix.net/nugl/default-profile.png",
        uid
      };
    case ActionTypes.SIGN_OUT_SUCCESS: {
      return null;
    }
    default:
      return state;
  }
};
