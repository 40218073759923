import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "./AppBar";
import Drawer from "./Drawer";
import { signOut } from "../../../actions/userActions";

const styles = theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    minWidth: 800,
    width: "calc(100% - 240px)"
  },
  toolbar: theme.mixins.toolbar
});

class AppLayout extends Component {
  handleLogoutClick = () => {
    this.props.signOut().then(() => this.props.history.push("/"));
  };
  render() {
    const {
      props: { children, classes }
    } = this;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar onLogoutClick={this.handleLogoutClick} />
        <Drawer />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    );
  }
}

AppLayout.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
  withRouter(
    connect(
      null,
      { signOut }
    )(AppLayout)
  )
);
