import axios from "axios";
import { auth } from "../firebase";

export const getOrigins = () => {
  return auth.currentUser.getIdToken().then(token => {
    return axios
      .get(`${process.env.REACT_APP_API_HOST}/config/origins`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        return response.data.whitelist;
      })
      .catch(err => {
        console.error(err);
      });
  });
};

export const getDevices = () => {
  return auth.currentUser.getIdToken().then(token => {
    return axios
      .get(`${process.env.REACT_APP_API_HOST}/config/devices`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.error(err);
      });
  });
};
