import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import logo from "../../../static/images/logo.svg";

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  toolBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  logo: {
    height: 32,
    marginRight: theme.spacing.unit * 2
  },
  title: {
    color: theme.palette.common.white,
    flexGrow: 1
  }
});

class NuglAppBar extends PureComponent {
  render() {
    const {
      props: { onLogoutClick, classes }
    } = this;
    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <img src={logo} className={classes.logo} alt="NUGL" />
          <Typography variant="h6" className={classes.title} noWrap>
            Admin Tool
          </Typography>
          <Button onClick={onLogoutClick}>Log Out</Button>
        </Toolbar>
      </AppBar>
    );
  }
}

NuglAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NuglAppBar);
