export const Types = [
  {
    value: "BLOCK",
    label: "Block"
  },
  {
    value: "HOME_SLIDER",
    label: "Home Slider"
  },
  {
    value: "LEADERBOARD",
    label: "Leaderboard"
  },
  {
    value: "MONSTER",
    label: "Monster"
  },
  {
    value: "MOBILE_BANNER",
    label: "Mobile Banner"
  }
];
